<app-no-records *ngIf="totalRecords==0 && !indexModel.Search else ShowRecord" [data]="noRecordData"
  (onClick)="onAddUpdateLookup('')">
</app-no-records>
<button #AddBtnHdn (click)="selectedLookupRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas" href="#lkPageSidebar"
  class="d-none page-header-action-items" data-title="Add New Product"> <i class="fa-regular fa-plus"></i></button>

<ng-template #ShowRecord>

  <!-- Page Header Start-->
  <div class="page-header">
    <div class="page-header-title">
      {{pageName}}
    </div>
    <div class="page-header-action">
      <!-- <button (click)="onAddUpdateLookup()" class="page-header-action-items" data-title="Add New"> <i
          class="fa-regular fa-plus"></i></button> -->

      <a href="javascript:void(0)" (click)="selectedLookupRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas"
        href="#lkPageSidebar" class="page-header-action-items" data-title="Add New Product"> <i
          class="fa-regular fa-plus"></i></a>


      <div class="page-header-action-search">
        <button type="button" (click)="onSearch()" class="btn-filter-search"><i
            class="fa-light fa-magnifying-glass"></i></button>
        <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
          class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search" />
      </div>
    </div>
  </div>
  <!-- Page Header End-->

  <!-- Main Page Content Start -->



  <div class="table-responsive table-shadow">
    <table class="custom-mat-table" mat-table [dataSource]="dataSource" matSort role="grid"
      (matSortChange)="sortData($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
      </ng-container>

      <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column!.Text}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column!.Value]}} </td>
      </ng-container>

      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element" class="table-action-btn">
          <a (click)="OnActiveStatus(element!.PageId)" data-title="Update Active Status">
            <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th width="120" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row" class="table-action-btn">
          <!--
          <a class="table-action-btn-edit" (click)="onAddUpdateLookup(row.PageId)" data-title="Edit">
            <i class="fa-light fa-pen"></i>
          </a> -->
          <a href="javascript:void(0)" class="table-action-btn-view"
            (click)="selectedLookupRecord=row.PageId;ViewMode='Detail'" data-title="View Detail"
            data-bs-toggle="offcanvas" href="#lkPageSidebar"><i class="fa-light fa-eye"></i>
          </a>

          <a class="table-action-btn-edit" data-bs-toggle="offcanvas" href="#PageSidebar"
            (click)="setSelectedRecord(row)" data-title="Change Content">
            <i class="bi bi-file-earmark-richtext"></i>
          </a>
          <a class="table-action-btn-delete" (click)="updateDeleteStatus(row.PageId)" data-title="Delete"> <i
              class="fa-light fa-trash-can"></i> </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="no-record-found text-center" *ngIf="totalRecords==0">
      <img src="assets/images/record-not-found.svg" alt="" />
      <p class="mb-0"> Sorry, No Record Found </p>
    </div>
  </div>

  <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">
    <mat-paginator #paginator [length]="totalRecords" [pageIndex]="(indexModel.Page-1)" [pageSize]="indexModel.PageSize"
      (page)="onPaginateChange($event)" showFirstLastButtons *ngIf="totalRecords!=0">
    </mat-paginator>
  </ul>


  <!-- Main Page Content End -->


  <div class="offcanvas offcanvas-end pagesidebar-large" tabindex="-1" id="PageSidebar" data-bs-keyboard="false">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"> {{selectedRecord.PageName | titlecase}} </h5>
      <div class="d-flex align-items-center">
        <a href="javascript:void(0)" class="offcanvas-close" #btnClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
      </div>
    </div>
    <div class="offcanvas-body ">
      <app-cmspage-add-update (OnSave)="reloadData($event)" [selectedRecord]="selectedRecord"></app-cmspage-add-update>


    </div>
  </div>


  <div class="offcanvas offcanvas-end pagesidebar-mini" tabindex="-1" id="lkPageSidebar" data-bs-keyboard="false">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"><span [ngSwitch]="ViewMode">
          <p *ngSwitchCase="'Add'" class="m-0">
            Add New {{pageName}}
          </p>
          <p *ngSwitchCase="'Edit'" class="m-0">
            Edit {{pageName}}
          </p>
          <p *ngSwitchDefault class="m-0">
            {{pageName}} Detail
          </p>
        </span></h5>
      <div class="d-flex align-items-center">
        <button *ngIf=" ViewMode!='Add'" (click)="changeViewMode()" class="page-header-action-items"
          data-title="{{ViewMode}}">
          <i [ngClass]="{'fa-light fa-eye':ViewMode=='Edit' ,'fa-light fa-pen':ViewMode=='Detail' }"></i>
        </button>
        <a href="javascript:void(0)" class="offcanvas-close" #btnLKClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
      </div>
    </div>
    <div class="offcanvas-body pt-3 offcanvas-body-fix-scroll">
      <app-lookups-add-edit [mode]="ViewMode" [ngClass]="{'disabled':  ViewMode=='Detail'}" [typeData]="typeData"
        (OnClose)="closeDrawer()" (OnSave)="reloadData($event.status)"
        [Id]="selectedLookupRecord"></app-lookups-add-edit>


    </div>
  </div>
</ng-template>
