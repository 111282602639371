import { Component, OnInit } from "@angular/core";
import { LookupTypeEnum } from "src/app/Shared/Enum/fixed-value";
import { AuthService } from "src/app/Shared/Helper/auth.service";
import { DropDownModel, MenuModel } from "src/app/Shared/Helper/common-model";
import { Routing_Url, DropDown_key } from "src/app/Shared/Helper/constants";
import { CommonService } from "src/app/Shared/Services/Common/common.service";
import { DashboardService } from "src/app/Shared/Services/Transection/dashboard.service";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  get routing_Url() { return Routing_Url }
  dropDown = new DropDownModel();
  menuModel!: MenuModel[];
  version = "1.0.3";

  constructor(private readonly _authService: AuthService, private readonly _commonService: CommonService, public readonly _dashboardService: DashboardService,) {

    this.menuModel = [
      { Name: "Dashboard", Icon: "bi bi-house-door", Url: "/admin" },
      {
        Name: "Products", Icon: "bi bi-box-seam", Url: "./product/list"
      },
      {
        Name: "Order", Icon: "bi bi-box-seam", Url: "./order/list"
      },
      {
        Name: "Customer", Icon: "bi bi-columns-gap", children: [
          { Name: "List", Icon: "bi bi-list-check", Url: "./customer/list" }
        ]
      },

      {
        Name: "Users", Icon: "bi bi-columns-gap", children: [
          { Name: "List", Icon: "bi bi-list-check", Url: "./user/list" }
        ]
      },

      {
        Name: "CMS Page", Icon: "bi bi-file-earmark-break", children: [
          { Name: "List", Icon: "bi bi-list-check", Url: "./master/cms-page" },
        ]
      },
      {
        Name: "General Entry", Icon: "bi bi-file-earmark-break", children: [
          { Name: "List", Icon: "bi bi-view-stacked", Url: "./master/general-entry" },
          { Name: "Category Master", Icon: "bi bi-gear-wide-connected", Url: "./master/general-entry-category" },

        ]
      },
      {
        Name: "Master", Icon: "bi bi-box-seam", children: []
      },
    ]
  }

  ngOnInit(): void {
    this._authService.IsAuthenticate();
    this.getLookupTypes();
    this._authService.IsAuthentication.subscribe(x => {

    });
  }

  getLookupTypes() {
    this._commonService.GetDropDown([DropDown_key.ddlLookupTypeMasters, DropDown_key.ddlGeneralEntryCategory]).subscribe(res => {
      if (res.IsSuccess) {

        let emus = [LookupTypeEnum.CMS_Page, LookupTypeEnum.State, LookupTypeEnum.Address_Type] as string[];
        let ddls = res.Data as DropDownModel;
        this.dropDown.ddlLookupTypeMaster = ddls.ddlLookupTypeMaster.filter(x => !emus.includes(x.Value));
        this.dropDown.ddlGeneralEntryCategory = ddls.ddlGeneralEntryCategory.map(r => { return { Text: r.Text.replace(" ", "-"), Value: r.Value } }) as any[];
        let idx = this.menuModel.findIndex(x => x.Name === 'Master');
        this.dropDown.ddlLookupTypeMaster.forEach(element => {
          this.menuModel[idx].children?.push({ Name: element.Text, Icon: "bi bi-house-door", Url: `./master/${element.Text.replace(" ", "-")}/${element.Value}` });
        });
        this.dropDown.ddlGeneralEntryCategory.forEach(element => {
          this.menuModel[idx].children?.push({ Name: element.Text, Icon: "bi bi-house-door", Url: `./forms/${element.Text.replace(" ", "~")}/${element.Value}` });
        });


      }
    });

  }
}
