import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ContentTypeEnum, RecordTypeEnum } from "src/app/Shared/Enum/fixed-value";
import { DropDownModel, FilterDropDownPostModel } from "src/app/Shared/Helper/common-model";
import { EditorConfig, DropDown_key, Message } from "src/app/Shared/Helper/constants";
import { FileInfo } from "src/app/Shared/Helper/shared/file-selector/file-selector.component";
import { CommonService } from "src/app/Shared/Services/Common/common.service";
import { GeneralEntryCategoryViewModel, GeneralEntryDataViewModel, GeneralEntryPostModel, GeneralEntryService, GeneralEntryViewModel } from "src/app/Shared/Services/Master/general-entry.service";

@Component({
  selector: 'app-general-entry-master-add-edit',
  templateUrl: './general-entry-master-add-edit.component.html',
  styleUrls: ['./general-entry-master-add-edit.component.scss']
})
export class GeneralEntryMasterAddEditComponent implements OnInit {

  pageName = 'General Entry'
  model = {} as GeneralEntryPostModel;
  modelBk = {} as GeneralEntryPostModel;
  dataItems: GeneralEntryDataViewModel[] = [];
  otherItems: GeneralEntryDataViewModel[] = [];

  fileSelector: any
  dropDown = new DropDownModel();
  formgrp = this.fb.group({
    Title: [undefined, Validators.required],//done
    ShortDescription: [undefined],//done
    ImagePath: [undefined],//done
    Description: [undefined],//done
    Url: [undefined],
    Keyword: [undefined],
    SortedOrder: [undefined, Validators.required],
    DataItems: [undefined],
    OtherDataItems: [undefined],
    Type: [undefined, Validators.required],
    Lookup: [undefined],
    SubLookup: [undefined],

  });
  recordTypeEnum = RecordTypeEnum;
  editorConfig = EditorConfig.Config;
  isFileAttached = false;
  contentTypeEnum = ContentTypeEnum;
  get f() { return this.formgrp.controls; }
  get subLookUp() { return this.model?.LookupId && this.dropDown?.ddlLookup?.length > 0 ? this.dropDown?.ddlLookup.find(x => x.Id == this.model.LookupId)?.Name : 'Sub lookup' }

  //get selectedType() { return this.CategoryId?.length > 0 ? this.dropDown?.ddlGeneralEntryCategory?.find(x => x.Value == this.CategoryId.toString()) : undefined }
  selectedType = {} as GeneralEntryCategoryViewModel;
  constructor(private readonly fb: FormBuilder,
    public _commonService: CommonService, private readonly toast: ToastrService, private readonly _generalEntryService: GeneralEntryService) {

  }
  get acceptedFiles() {
    return this.selectedType?.ContentType == (this.contentTypeEnum.Photo).toString() || this.selectedType?.ContentType == (this.contentTypeEnum.MultipleImages).toString() ? '.jpeg,.gif,.png,.jpg,.webp' :
      this.selectedType?.ContentType == (this.contentTypeEnum.Document).toString() ? '.doc,.docx,.ppt,.pptx,.pdf,.xlx,.xlsx,.txt' :
        this.selectedType?.ContentType == (this.contentTypeEnum.Video).toString() ? '.mp4,.mkv,.avi' : ''
  }

  @Input() set Id(value: string) {
    this.reset();
    this.model.Id = value;
    if (this.model !== null && this.model.Id.length > 0) {
      this.onGetDetail();
    }
  }

  @Input('typeData') set setTypeData(value: GeneralEntryCategoryViewModel) {
    this.selectedType = value;
    this.model.CategoryId = this.selectedType.Id;
    this.pageName = this.selectedType.Name;
    this.GetDropDown();
  }


  @Output() OnSave = new EventEmitter<{ status: boolean, recordId: string }>();
  @Output() OnClose = new EventEmitter<boolean>();

  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['Id']?.previousValue != changes['Id']?.currentValue) {
      this.reset();

    }
    if (changes['CategoryId']?.currentValue != changes['CategoryId']?.currentValue) {
      this.reset();

    }

  }

  onDocumentAttach(file: FileInfo[]) {
    this.model.ImagePath = file[0].FileBase64;
    this.isFileAttached = true;
  }
  RemoveDocument(file: string) {
    this.model.ImagePath = '';
  }

  GetDropDown() {
    let itm = [DropDown_key.ddlRecordType];
    if (this.selectedType?.LookupType?.length > 0) {
      itm.push(this.selectedType.LookupTypeId);
    }
    let serve = this._commonService.GetDropDown(itm, false).subscribe(res => {
      serve.unsubscribe();
      if (res.IsSuccess) {
        const ddls = res?.Data as DropDownModel;
        this.dropDown.ddlRecordType = ddls?.ddlRecordType;
        this.dropDown.ddlLookup = res.Data[`${this.selectedType.LookupTypeId}`]
      }
    });
  }
  getSubLookUpDropDown(value: string) {

    if (value?.length > 0 && this.selectedType.IsSubLookupType) {
      const ddlModel = {} as FilterDropDownPostModel;
      ddlModel.FilterFromKey = DropDown_key.ddlLookup;
      ddlModel.Key = DropDown_key.ddlSublookup;
      ddlModel.Values = [value];
      this.dropDown.ddlSublookup = [];

      this._commonService.GetFilterDropDown(ddlModel)?.subscribe(x => {
        if (x.IsSuccess) {
          const ddls = x?.Data as DropDownModel;
          this.dropDown.ddlSublookup = Object.assign(ddls.ddlSublookup, []);
        }

        if (this.dropDown.ddlSublookup?.findIndex(x => x.Value == this.model.SubLookupId) == -1) {
          this.model.SubLookupId = null;
        }
        if (this.dropDown.ddlSublookup?.findIndex(x => x.Value == this.modelBk.SubLookupId) >= 0) {
          this.model.SubLookupId = this.modelBk.SubLookupId;
        }
      });
    } else {
      this.dropDown.ddlSublookup = [];
    }
  }

  deleteItems(id: string, isDataItem = true) {
    this._commonService.Question(Message.DeleteConfirmation as string).then(result => {
      if (result) {
        let subscription = this._generalEntryService.DeleteGeneralEntryItems(id).subscribe(
          data => {
            subscription.unsubscribe();
            if (data.IsSuccess) {
              this._commonService.Success(data.Message as string)
              if (isDataItem) {

                const idx = this.dataItems.findIndex(x => x.Id == id);
                this.dataItems.splice(idx, 1);
              } else {
                const idx = this.otherItems.findIndex(x => x.Id == id);
                this.otherItems.splice(idx, 1);

              }
            }
          },
          error => {
            this._commonService.Error(error.message as string)

          }
        );
      }
    });
  }

  getFileType(fileName: string) {

    const ext = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['doc', 'docx', 'ppt', 'pptx', 'pdf', 'txt', 'xlx', 'xlsx'].some(x => x.toLowerCase() === ext)) {
      return 'doc';
    } else if (['jpeg', 'gif', 'png', 'jpg', 'svg', 'webp'].some(x => x.toLowerCase() === ext)) {
      return 'image';
    }
    else if (['mp4', 'mkv', 'avi',].some(x => x.toLowerCase() === ext)) {
      return 'video';
    } else {
      return ext;
    }

  }

  onFileAttach(file: FileInfo[]) {

    if (file.length > 0) {
      if (!this.model.Data) {
        this.model.Data = [];
      }
      this.model.Data = file.map(x => { return x.FileBase64 });

    } else {
      this.model.Data = [];
    }
  }
  onSubmit() {
    this.formgrp.markAllAsTouched();
    if (this.formgrp.valid) {
      this.model.Data = this.model.Data ? (Array.isArray(this.model.Data) ? this.model.Data : [this.model.Data]) as string[] : this.model.Data;

      this.model.Keyword = this.model.Keyword == null ? '' : this.model.Keyword;
      this.model.Description = this.model.Description == null ? '' : this.model.Description;

      this._generalEntryService.AddUpdateGeneralEntry(this.model).subscribe(x => {
        if (x.IsSuccess) {
          this.toast.success(`${this.pageName} ${this.model.Id?.length ? 'update' : 'added'}  successfully.`, "Save");
          //  this._route.navigate(['./admin/master/general-entry']);
          this.OnSave.emit({ status: true, recordId: x.Data as string });
        } else {
          this.OnSave.emit({ status: false, recordId: x.Data as string });
          this.toast.error(x.Message as string, "Failed");
        }
      })
    }
  }

  onGetDetail() {
    this._generalEntryService.GetGeneralEntry(this.model.Id, true).subscribe(response => {
      if (response.IsSuccess) {

        const data = response.Data as GeneralEntryViewModel;
        this.model.Id = data.Id;
        this.model.CategoryId = data.CategoryId;
        this.model.Title = data.Title;
        this.model.Description = data.Description;
        this.model.ShortDescription = data.ShortDescription;
        this.model.SortedOrder = data.SortedOrder;
        this.model.ImagePath = data.ImagePath;
        this.model.Url = data.Url;
        this.dataItems = data.Data;
        this.otherItems = data.OtherData;

        this.model.LookupId = data.LookupId;
        this.model.SubLookupId = data.SubLookupId;
        this.model.Lookup = data.Lookup;
        this.model.SubLookup = data.SubLookup;
        this.model.Type = data.Type;
        this.model.TypeName = data.TypeName;
        this.model.Keyword = data.Keyword;
        this.modelBk = Object.assign({}, this.model);
      } else {
        this.toast.error(response.Message?.toString(), 'Error');
      }
    },
      error => {
      });
  }
  reset() {
    this.model = {} as GeneralEntryPostModel;
    this.formgrp.reset();
    this.model.CategoryId = this.selectedType.Id;
  }
  onClose() {

    this.OnClose.emit(true);
  }

}
