<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    {{pageName | titlecase}}
  </div>
  <div class="page-header-action">
    <button routerLink="/admin/master/cms-page" class="page-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->


<div class="row m-0 content-box">
  <div class="cmis-page-create-from">
    <form [formGroup]="formgrp" (ngSubmit)="SaveData()">
      <div class="row">
        <div class="col-md-12 mb-3">
          <label>Display order</label>
          <input type="text" [(ngModel)]="model.SortedOrder" (keypress)="_commonService.NumberOnly($event)"
            [minlength]="1" [maxLength]="6" formControlName="SortedOrder"
            [ngClass]="{ 'is-invalid': f.SortedOrder.errors && f.SortedOrder.touched}" class="form-control" id="input4"
            placeholder="Display Order">
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('SortedOrder')?.hasError('required') && formgrp.get('SortedOrder')?.touched)">
            Enter Display Order </div>
          <div class="invalid-feedback"
            *ngIf="!formgrp.get('SortedOrder')?.hasError('required') && ((formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength')) && formgrp.get('SortedOrder')?.touched) || (formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength'))">
            Display Order length must be 1-6 digit. </div>
        </div>

        <div class="col-md-12 mb-3">
          <label>Heading</label>
          <!-- <angular-editor [(ngModel)]="model.Heading" formControlName="Heading" [config]="editorConfig"
          placeholder="Heading" [ngClass]="{ 'is-invalid': f.Heading.errors && f.Heading.touched}" id="Heading">
        </angular-editor> -->
          <textarea [(ngModel)]="model.Heading" formControlName="Heading" placeholder="Heading" class="form-control"
            rows="5" [ngClass]="{ 'is-invalid': f.Heading.errors && f.Heading.touched}" id="Heading"></textarea>

          <div class="invalid-feedback"
            *ngIf="(formgrp.get('Heading')?.hasError('required') && formgrp.get('Heading')?.touched)">
            Please enter Heading</div>
        </div>
        <div class="col-md-12 mb-3">
          <label>Content</label>
          <angular-editor *ngIf="isEditorReady" class="angular-fix-min-height" [(ngModel)]="model.Content" formControlName="Content"
            [config]="editorConfig" placeholder="Content"
            [ngClass]="{ 'is-invalid': f.Content.errors && f.Content.touched}" id="Content">
          </angular-editor>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('Content')?.hasError('required') && formgrp.get('Content')?.touched)">
            Please enter Content</div>
        </div>
      </div>
      <div class="form-footer-btn-grp widget-content widget-content text-center mt-3">
        <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i> Save</button>
        <button type="button" class="btn btn-default btn-border" (click)="onCancel()"> <i class="fas fa-times me-1"></i>
          Cancel</button>
      </div>
    </form>
  </div>

  <div class="cmis-page-preview" *ngIf="postModel?.length">
    <div class="cmis-page-preview-header"> <i class="fa-light fa-eye"></i> Preview </div>
    <div class="cmis-page-preview-body">
      <ng-container *ngFor="let item of getList">
        <div class="cmis-page-preview-items">
          <ng-container *ngIf="item.Id!==model.Id">
            <div class="cmis-page-preview-items-action-info">
              <h3 [innerHtml]="item.Heading"></h3>
              <p class="m-0" [innerHtml]="item.Content"></p>
            </div>
            <div class="cmis-page-preview-items-action mt-3">
              <a href="javascript:void()" class="cmis-page-preview-items-action-btn text-dark" (click)="editItem(item)"
                data-title="Edit"><i class="fa-light fa-pen"></i></a>
              <a href="javascript:void()" class="cmis-page-preview-items-action-btn text-danger"
                (click)="deleteItem(item)" data-title="Delete"><i class="fa-light fa-trash-can"></i></a>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
