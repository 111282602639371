<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    {{pageName | titlecase}}
  </div>
  <div class="page-header-action">
    <button routerLink="{{model.Id.length > 0 ?'../../' :'../'}}" class="page-header-action-items"
      data-title="Back to list"> <i class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->

<div class="content-box">
  <form [formGroup]="formgrp" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Done -->
      <div class="col-xxl-8 col-md-6 mb-3">
        <label>Title<span class="text-danger">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="model.Title" formControlName="Title"
          [ngClass]="{ 'is-invalid': f.Title.errors && f.Title.touched}" placeholder="Title">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Title')?.hasError('required') && formgrp.get('Title')?.touched)">
          Please enter <strong>Title</strong>...!</div>
      </div>

      <div class="col-xxl-2 col-md-3 mb-3">
        <label> Type<span class="text-danger">*</span></label>
        <ng-select [items]="dropDown.ddlRecordType" formControlName="Type" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select   Type" [ngClass]="{ 'is-invalid': f.Type.errors && f.Type.touched}"
          [(ngModel)]="model.Type">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Type')?.hasError('required') && formgrp.get('Type')?.touched)">
          Please select <strong> Type</strong>...!
        </div>
      </div>

      <div class="col-xxl-2 col-md-3 mb-3">
        <label>Sort Order<span class="text-danger">*</span></label>

        <input type="number" [(ngModel)]="model.SortedOrder" (keypress)="_commonService.NumberOnly($event)"
          [minlength]="1" [maxLength]="6" formControlName="SortedOrder"
          [ngClass]="{ 'is-invalid': f.SortedOrder.errors && f.SortedOrder.touched}" class="form-control" id="input4"
          placeholder="Order">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('SortedOrder')?.hasError('required') && formgrp.get('SortedOrder')?.touched)">
          Enter Sort Order </div>
        <div class="invalid-feedback"
          *ngIf="!formgrp.get('SortedOrder')?.hasError('required') && ((formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength')) && formgrp.get('SortedOrder')?.touched) || (formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength'))">
          Sort Order length must be 1-6 digit. </div>


      </div>

      <div class="master-form-auto">
        <div class="master-form-auto-col mb-3" *ngIf="selectedType.IsLookupType">
          <label> {{selectedType.LookupType }}</label>
          <ng-select [items]="dropDown.ddlLookup" formControlName="Lookup" [searchable]="true" bindLabel="Name"
            bindValue="Id" placeholder="Select {{selectedType.LookupType }}"
            (ngModelChange)="getSubLookUpDropDown($event)"
            [ngClass]="{ 'is-invalid': f.Lookup.errors && f.Lookup.touched}" [(ngModel)]="model.LookupId">
          </ng-select>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('Type')?.hasError('required') && formgrp.get('Type')?.touched)">
            Please select <strong> {{selectedType.LookupType }} </strong>...!
          </div>
        </div>

        <div class="master-form-auto-col mb-3" *ngIf="selectedType.IsSubLookupType">
          <label> {{subLookUp??'Sub lookup'}}</label>
          <ng-select [items]="dropDown.ddlSublookup" formControlName="SubLookup" [searchable]="true" bindLabel="Text"
            bindValue="Value" placeholder="Select {{subLookUp }}"
            [ngClass]="{ 'is-invalid': f.SubLookup.errors && f.SubLookup.touched}" [(ngModel)]="model.SubLookupId">
          </ng-select>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('Type')?.hasError('required') && formgrp.get('Type')?.touched)">
            Please select <strong> {{selectedType.LookupType }} </strong>...!
          </div>
        </div>

        <div class="master-form-auto-col mb-3" *ngIf="selectedType?.IsShowThumbnail">
          <!-- <label> Image  <span class="label-info"> (800*500) </span></label> -->
          <label> Image </label>
          <input type="hidden" name="ImagePath" [(ngModel)]="model.ImagePath" formControlName="ImagePath">
          <app-file-selector [MaxFileLength]="1" (FilesChanged)="onDocumentAttach($event)"
            [CurrentFileLength]="model!.ImagePath ? 1: 0" [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'"
            [ngClass]="{ 'is-invalid': f.ImagePath.errors && f.ImagePath.touched}">
          </app-file-selector>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('ImagePath')?.hasError('required') && formgrp.get('ImagePath')?.touched)">
            Please upload image...!</div>

          <span *ngIf="model.ImagePath && !isFileAttached  && model.Id.length>0">

            <a href="javascript:void()" class="small-media-items position-relative">
              <div class="small-media-items-img"> <img [src]="model.ImagePath" ViewImage
                   /> </div>
              <div class="small-media-items-action">
                <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                    class="fa-light fa-trash-can"></i></a>
                <a [href]="model.ImagePath" target="_blank">
                  <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                </a>
              </div>
            </a>

          </span>

        </div>

        <div class="master-form-auto-col mb-3"
          *ngIf="selectedType?.IsFileUploader && (selectedType?.ContentType==(contentTypeEnum.Photo).toString() || selectedType?.ContentType==(contentTypeEnum.MultipleImages).toString() ||selectedType?.ContentType==(contentTypeEnum.Document).toString() || selectedType?.ContentType==(contentTypeEnum.Video).toString())">
          <label> Attachments</label>
          <div class="dropzone dropzone-light upload-btn-wrapper w-100">
            <input type="hidden" name="DataItems" value="{{this.model!.Data?.length==0 ? dataItems: this.model.Data}}"
              formControlName="DataItems">
            <app-file-selector [MaxFileLength]="selectedType?.ContentType==(contentTypeEnum.Photo).toString() ?1:5"
              (FilesChanged)="onFileAttach($event)"
              [CurrentFileLength]="(this.dataItems.length +(this.model.Data?.length??0))" [FileFilter]="acceptedFiles"
              [ngClass]="{ 'is-invalid': f.DataItems.errors && f.DataItems.touched}">
            </app-file-selector>
            <div class="invalid-feedback"
              *ngIf="(formgrp.get('DataItems')?.hasError('required') && formgrp.get('DataItems')?.touched)">
              Please Add File...!</div>
          </div>
          <div class="d-flex flex-wrap align-items-center upload-content-list" *ngIf="dataItems">
            <ng-container *ngFor="let itm of dataItems">
              <span [ngSwitch]="getFileType(itm.Value)">

                <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative">
                  <div class="small-media-items-img"> <img [src]="itm.Value" ViewImage
                       /> </div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,true)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>
                <a *ngSwitchCase="'doc'" href="javascript:void()"
                  class="small-media-items small-media-items-pdf text-danger position-relative mb-3">
                  <div class="small-media-items-img"><i class="bi bi-file-earmark-pdf"></i></div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,true)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>


                <a *ngSwitchCase="'video'" href="javascript:void()"
                  class="small-media-items small-media-items-video position-relative mb-3">
                  <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,true)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>

              </span>
            </ng-container>

          </div>
        </div>

        <div class="master-form-auto-col mb-3"
          *ngIf="selectedType?.IsOtherFileUploader && (selectedType?.ContentType==(contentTypeEnum.Photo).toString() || selectedType?.ContentType==(contentTypeEnum.MultipleImages).toString() ||selectedType?.ContentType==(contentTypeEnum.Document).toString() || selectedType?.ContentType==(contentTypeEnum.Video).toString())">
          <label> Secondary Attachments <span class="info-tooltip"> <i class="fa-regular fa-circle-info"></i>
              <div class="info-tooltip-hover"> Preferred size : 500*500 </div>
            </span></label>
          <div class="dropzone dropzone-light upload-btn-wrapper w-100">
            <input type="hidden" name="OtherDataItems"
              value="{{this.model!.OtherData?.length==0 ? otherItems: this.model.OtherData}}"
              formControlName="OtherDataItems">
            <app-file-selector [MaxFileLength]="selectedType?.ContentType==(contentTypeEnum.Photo).toString() ?1:5"
              (FilesChanged)="onFileAttach($event)"
              [CurrentFileLength]="(this.otherItems.length +(this.model.OtherData?.length??0))"
              [FileFilter]="acceptedFiles"
              [ngClass]="{ 'is-invalid': f.OtherDataItems.errors && f.OtherDataItems.touched}">
            </app-file-selector>
            <div class="invalid-feedback"
              *ngIf="(formgrp.get('OtherDataItems')?.hasError('required') && formgrp.get('OtherDataItems')?.touched)">
              Please Add File...!</div>
          </div>
          <div class="d-flex flex-wrap align-items-center upload-content-list" *ngIf="otherItems">
            <ng-container *ngFor="let itm of otherItems">
              <span [ngSwitch]="getFileType(itm.Value)">

                <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative">
                  <div class="small-media-items-img"> <img [src]="itm.Value" ViewImage
                       /> </div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,false)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>
                <a *ngSwitchCase="'doc'" href="javascript:void()"
                  class="small-media-items small-media-items-pdf text-danger position-relative mb-3">
                  <div class="small-media-items-img"><i class="bi bi-file-earmark-pdf"></i></div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,false)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>


                <a *ngSwitchCase="'video'" href="javascript:void()"
                  class="small-media-items small-media-items-video position-relative mb-3">
                  <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
                  <div class="small-media-items-action">
                    <a href="javascript:void()" (click)="deleteItems(itm.Id,false)" title="Delete"><i
                        class="fa-light fa-trash-can"></i></a>
                    <a [href]="itm.Value" target="_blank">
                      <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                    </a>
                  </div>
                </a>

              </span>
            </ng-container>

          </div>
        </div>
      </div>
      <div class="col-md-12 mb-3" *ngIf="selectedType?.IsShowUrl">
        <label>Link </label>
        <input type="text" class="form-control" [(ngModel)]="model.Url" formControlName="Url"
          [ngClass]="{ 'is-invalid': f.Url.errors && f.Url.touched}" placeholder="Link">
        <div class="invalid-feedback" *ngIf="(formgrp.get('Url')?.hasError('required') && formgrp.get('Url')?.touched)">
          Please enter <strong>Link</strong>...!</div>
      </div>

      <div class="col-md-12 mb-3" *ngIf="selectedType?.IsShortDesc">
        <label>Short Description</label>
        <angular-editor [(ngModel)]="model.ShortDescription" formControlName="ShortDescription" [config]="editorConfig"
          placeholder="ShortDescription"
          [ngClass]="{ 'is-invalid': f.ShortDescription.errors && f.ShortDescription.touched}"
          id="ShortDescription"></angular-editor>


        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ShortDescription')?.hasError('required') && formgrp.get('ShortDescription')?.touched)">
          Please enter Short Description</div>
      </div>

      <div class="col-md-12 mb-3" *ngIf="selectedType?.IsFullDesc">
        <label>Description</label>
        <angular-editor [(ngModel)]="model.Description" formControlName="Description" [config]="editorConfig"
          placeholder="Description" [ngClass]="{ 'is-invalid': f.Description.errors && f.Description.touched}"
          id="Description"></angular-editor>


        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Description')?.hasError('required') && formgrp.get('Description')?.touched)">
          Please enter Description</div>
      </div>

      <div class="col-md-12 mb-3" *ngIf="selectedType?.IsKeyword">
        <label>Keyword</label>
        <textarea [(ngModel)]="model.Keyword" formControlName="Keyword" placeholder="Keyword" class="form-control"
          [ngClass]="{ 'is-invalid': f.Keyword!.errors && f.Keyword!.touched}" id="Keyword"></textarea>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Keyword')?.hasError('required') && formgrp.get('Keyword')?.touched)">
          Please enter Keyword</div>
      </div>
    </div>

    <div class="offcanvas-footer-fix">
      <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i>
        Save</button>
      <button type="button" (click)="onClose()" class="btn btn-default btn-border"> <i class="fas fa-times me-1"></i>
        Cancel</button>
    </div>
  </form>
</div>
