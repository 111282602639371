import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RecordTypeEnum } from "../../Enum/fixed-value";
import { BaseAPIService } from "../../Helper/base-api.service";
import { IndexModel, ApiResponse, Dictionary } from "../../Helper/common-model";
import { OrderStatusEnum } from "../../Helper/constants";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private readonly _baseService: BaseAPIService) { }

  GetTopRecord(indexModel: IndexModel, data: any): Observable<ApiResponse<TopRecordReportDTOModel[]>> {
    let url = `${this._baseService.API_Url.Report_GetTopRecord_Api}`;
    let param = new Dictionary<any>();
    param.Add("status", data?.status ?? OrderStatusEnum.Confirmed);
    param.Add("fromDate", data?.fromDate ?? new Date(new Date().getDate() - 7).toLocaleDateString());
    param.Add("toDate", data?.toDate ?? new Date().toLocaleDateString());
    param.Add("type", data.type ?? RecordTypeEnum.All);
    param.Add("page", indexModel.Page);
    param.Add("pageSize", indexModel.PageSize);
    param.Add("orderBy", indexModel.OrderBy);
    param.Add("orderByAsc", indexModel.OrderByAsc);
    return this._baseService.get(url, param);
  }
  GetTopCustomerRecord(indexModel: IndexModel, data: any): Observable<ApiResponse<TopCustomerReportDTOModel[]>> {
    let url = `${this._baseService.API_Url.Report_GetTopCustomerRecord_Api}`;
    let param = new Dictionary<any>();
    param.Add("status", data?.status ?? OrderStatusEnum.Confirmed);
    param.Add("fromDate", data?.fromDate ?? new Date(new Date().getDate() - 7).toLocaleDateString());
    param.Add("toDate", data?.toDate ?? new Date().toLocaleDateString());
    param.Add("type", data.type ?? RecordTypeEnum.All);
    param.Add("page", indexModel.Page);
    param.Add("pageSize", indexModel.PageSize);

    return this._baseService.get(url, param);
  }

  GetFinancialRecord(data: any): Observable<ApiResponse<FinancialReportDTO[]>> {
    let url = `${this._baseService.API_Url.Report_GetFinancialRecord_Api}`;
    let param = new Dictionary<any>();
    param.Add("fromDate", data?.fromDate?.toLocaleDateString('en-US') ?? new Date(new Date().getDate() - 7).toLocaleDateString('en-US'));
    param.Add("toDate", data?.toDate?.toLocaleDateString('en-US') ?? new Date().toLocaleDateString('en-US'));
    param.Add("type", data?.type ?? RecordTypeEnum.All);
    return this._baseService.get(url, param);
  }


}
export interface TopRecordReportDTOModel {
  ProductId: string;
  Name: string;
  UniqueID: string;
  URLId: string;
  ImagePath: string;
  BtoBPrice: number;
  BtoBSellingPrice: number;
  BtoBDiscount: number;
  SellingPrice: number;
  Discount: number;
  Price: number;
}
export interface TopCustomerReportDTOModel {
  CustomerUserId: string;
  CustomerName: string;
  TotalAmount: number;
  TotalOrder: number;
  Quantity: number;
}

export interface FinancialReportDTO {
  StatusId: string;
  Status: string;
  TotalAmount: number;
  TotalOrders: number;
}
