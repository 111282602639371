<form [formGroup]="formgrp" (ngSubmit)="onSubmit()">
    <div class="row mobile-row-m-0">

        <!-- Name Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.Name"
                formControlName="Name"
                [ngClass]="{ 'is-invalid': f.Name.errors && f.Name.touched}"
                placeholder="Name"
                id="Name"
            />
            <label for="Name">Name</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('Name')?.hasError('required') && formgrp.get('Name')?.touched)">
                Please Enter <strong>Name</strong>...!
            </div>
        </div>
        </div>

        <!-- Sort Order Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50">
            <div class="form-floating">
            <input
                type="text"
                [(ngModel)]="model.SortedOrder"
                (keypress)="_commonService.NumberOnly($event)"
                [minlength]="1"
                [maxLength]="6"
                formControlName="SortedOrder"
                [ngClass]="{ 'is-invalid': f.SortedOrder.errors && f.SortedOrder.touched}"
                class="form-control"
                placeholder="Sort Order"
                id="SortOrder"
            />
            <label for="SortOrder">Sort Order</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('SortedOrder')?.hasError('required') && formgrp.get('SortedOrder')?.touched)">
                Enter Sort Order
            </div>
            <div class="invalid-feedback" *ngIf="!formgrp.get('SortedOrder')?.hasError('required') && ((formgrp.get('SortedOrder')?.hasError('maxlength') || formgrp.get('SortedOrder')?.hasError('minlength')) && formgrp.get('SortedOrder')?.touched)">
                Sort Order length must be 1-6 digits.
            </div>
        </div>
        </div>

        <!-- Image Upload -->
        <div class="col-md-12 mb-3" *ngIf="_typeData?.IsImage">
      <label> Image  <span class="info-tooltip"> <i class="fa-regular fa-circle-info"></i> <div class="info-tooltip-hover"> Preferred size : 800*700 </div></span></label>

            <input type="hidden" name="ImagePath" [(ngModel)]="model.ImagePath" formControlName="ImagePath">
            <app-file-selector
                [MaxFileLength]="1"
                (FilesChanged)="onDocumentAttach($event)"
                [CurrentFileLength]="model?.ImagePath ? 1 : 0"
                [ngClass]="{ 'is-invalid': f.ImagePath.errors && f.ImagePath.touched}"
            >
            </app-file-selector>
            <div class="invalid-feedback" *ngIf="(formgrp.get('ImagePath')?.hasError('required') && formgrp.get('ImagePath')?.touched)">
                Please Add Photo
            </div>

            <!-- Existing Image Section -->
            <ng-container *ngIf="model.ImagePath && !isFileAttached && model.Id.length">
                <div class="dropzone dropzone-light file-upload-details">
                    <div class="dz-details">
                        <div class="dz-filename"> <i class="fa-regular fa-image"></i> <span data-dz-name="">{{ getFileName }}</span></div>
                    </div>
                    <a [href]="model.ImagePath" target="_blank">
                        <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye file-upload-details-remove"></i>
                    </a>
                    <i (click)="RemoveDocument(model.ImagePath)" data-bs-toggle="tooltip" data-placement="bottom" title="Remove" class="fas fa-trash-alt text-danger file-upload-details-remove"></i>
                </div>
            </ng-container>
        </div>

        <!-- Meta Title Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50" *ngIf="_typeData?.IsSeofield">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.MetaTitle"
                formControlName="MetaTitle"
                [ngClass]="{ 'is-invalid': f.MetaTitle.errors && f.MetaTitle.touched}"
                placeholder="Meta Title"
                id="MetaTitle"
            />
            <label for="MetaTitle">Meta Title</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('MetaTitle')?.hasError('required') && formgrp.get('MetaTitle')?.touched)">
                Please Enter <strong>Meta Title</strong>...!
            </div>
        </div>
        </div>

        <!-- Page URL Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50" *ngIf="_typeData?.IsInfo">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.Url"
                formControlName="Url"
                [ngClass]="{ 'is-invalid': f.Url.errors && f.Url.touched}"
                placeholder="Page Url"
                id="PageUrl" RestrictInput
            />
            <label for="PageUrl">Page Url</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('Url')?.hasError('required') && formgrp.get('Url')?.touched)">
                Please Enter <strong>Url</strong>...!
            </div>
        </div>
        </div>

        <!-- Meta Description Field -->
        <div class="col-md-12 mb-3" *ngIf="_typeData?.IsSeofield">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.MetaDescription"
                formControlName="MetaDescription"
                [ngClass]="{ 'is-invalid': f.MetaDescription.errors && f.MetaDescription.touched}"
                placeholder="Meta Description"
                id="MetaDescription"
            />
            <label for="MetaDescription">Meta Description</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('MetaDescription')?.hasError('required') && formgrp.get('MetaDescription')?.touched)">
                Please Enter <strong>Meta Description</strong>...!
            </div>
        </div>
        </div>

        <!-- Page Content Field -->
        <div class="col-md-12 mb-3" *ngIf="_typeData?.IsInfo">
            <label>Page Content</label>
            <angular-editor
                [(ngModel)]="model.Info"
                formControlName="Info"
                [config]="editorConfig"
                [ngClass]="{ 'is-invalid': f.Info.errors && f.Info.touched}"
                id="Info"
                rows="5"
                placeholder="Add Page Content"
            ></angular-editor>
            <div class="invalid-feedback" *ngIf="(formgrp.get('Info')?.hasError('required') && formgrp.get('Info')?.touched)">
                Please enter Info...!
            </div>
        </div>

        <!-- B2B Meta Title Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50" *ngIf="_typeData?.IsBtoBseofield">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.BtoBMetaTitle"
                formControlName="BtoBMetaTitle"
                [ngClass]="{ 'is-invalid': f.BtoBMetaTitle.errors && f.BtoBMetaTitle.touched}"
                placeholder="B2B Meta Title"
                id="B2BMetaTitle"
            />
            <label for="B2BMetaTitle">B2B Meta Title</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('BtoBMetaTitle')?.hasError('required') && formgrp.get('BtoBMetaTitle')?.touched)">
                Please Enter <strong>B2B Meta Title</strong>...!
            </div>
        </div>
        </div>

        <!-- B2B Page Url Field -->
        <div class="col-md-12 mb-3 pagesidebar-medium-col-50" *ngIf="_typeData?.IsBtoBinfo">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.BtoBUrl"
                formControlName="BtoBUrl"
                [ngClass]="{ 'is-invalid': f.BtoBUrl.errors && f.BtoBUrl.touched}"
                placeholder="B2B Url" RestrictInput
                id="B2BPageUrl"
            />
            <label for="B2BPageUrl">B2B Page Url</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('BtoBUrl')?.hasError('required') && formgrp.get('BtoBUrl')?.touched)">
                Please Enter <strong>B2B Url</strong>...!
            </div>
        </div>
        </div>

        <!-- B2B Meta Description Field -->
        <div class="col-md-12 mb-3" *ngIf="_typeData?.IsBtoBseofield">
            <div class="form-floating">
            <input
                type="text"
                class="form-control"
                [(ngModel)]="model.BtoBMetaDescription"
                formControlName="BtoBMetaDescription"
                [ngClass]="{ 'is-invalid': f.BtoBMetaDescription.errors && f.BtoBMetaDescription.touched}"
                placeholder="B2B Meta Description"
                id="B2BMetaDescription"
            />
            <label for="B2BMetaDescription">B2B Meta Description</label>
            <div class="invalid-feedback" *ngIf="(formgrp.get('BtoBMetaDescription')?.hasError('required') && formgrp.get('BtoBMetaDescription')?.touched)">
                Please Enter <strong>B2B Meta Description</strong>...!
            </div>
        </div>
        </div>

        <!-- B2B Page Content Field -->
        <div class="col-md-12 mb-3" *ngIf="_typeData?.IsBtoBinfo">
            <label>B2B Page Content</label>
            <angular-editor
                [(ngModel)]="model.BtoBInfo"
                formControlName="BtoBInfo"
                [config]="editorConfig"
                [ngClass]="{ 'is-invalid': f.BtoBInfo.errors && f.BtoBInfo.touched}"
                id="BtoBInfo"
                rows="5"
                placeholder="Add B2B Page Content"
            ></angular-editor>
            <div class="invalid-feedback" *ngIf="(formgrp.get('BtoBInfo')?.hasError('required') && formgrp.get('BtoBInfo')?.touched)">
                Please enter B2B Info...!
            </div>
        </div>

    </div>

    <!-- Submit & Cancel Buttons -->
    <div class="offcanvas-footer-fix">
        <button type="submit" class="btn btn-default">
            <i class="fas fa-check me-1"></i> Submit
        </button>
        <button
            type="button"
            (click)="onClose()"
            class="btn btn-default btn-border"
            aria-label="Close"
        >
            <i class="fas fa-times me-1"></i> Cancel
        </button>
    </div>
</form>
