import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Output } from '@angular/core';

@Directive({
  selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective {
  @Output() scrolled = new EventEmitter<void>();
  private lastScrollTop = 0;
  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) {

  }

  ngOnInit(): void {

    setTimeout(() => {
      // this.el?.nativeElement?.classList?.add('scroll-container');
    }, 200);
  }
  @HostListener('window:scroll', [])
  onWindowScroll(): void {

    const windowHeight = window.innerHeight; // Height of the viewport
    const documentHeight = document.documentElement.scrollHeight; // Total height of the document
    const windowScroll = window.scrollY; // Current scroll position from the top
    // Get the footer height
    const footer = document.querySelector('app-footer')??null; // Adjust the selector as needed
    const footerHeight = footer ? footer.clientHeight +50: 0;
    const ignoreContent = document.querySelector('.onpage-seo-content')??null; // Adjust the selector as needed
    const ignoreContentHeight = ignoreContent ? ignoreContent.clientHeight +50: 0;

    // Check if the user has scrolled down and reached the bottom, ignoring footer height
    if (windowScroll > this.lastScrollTop && windowScroll + windowHeight >= documentHeight - (footerHeight + ignoreContentHeight) - 10) { // 10px buffer
      this.scrolled.emit();
    }
    this.lastScrollTop = windowScroll;
  }

}
