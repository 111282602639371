<!-- Page Header Start-->
<div class="page-header">
  <div class="page-header-title">
    Dashboard

  </div>
  <div class="page-header-action">
    <div class="page-filter-form">
      <div class="mini-filter-form-items date-picker-icon">
        <date-range-picker [(ngModel)]="dateValue" (onChange)="getCount()"></date-range-picker>
        <i class="fa-light fa-calendar-days"></i>
      </div>
      <div class="mini-filter-form-items">
        <select class="form-select" [(ngModel)]="recordType" (change)="getCount()">
          <option [value]="recordTypeEnum!.All" [selected]="recordTypeEnum!.All == recordType">All </option>
          <option [value]="recordTypeEnum!.BtoC" [selected]="recordTypeEnum!.BtoC == recordType">B2C</option>
          <option [value]="recordTypeEnum!.BtoB" [selected]="recordTypeEnum!.BtoB == recordType">B2B </option>
        </select>
      </div>
    </div>
  </div>
</div>
<!-- Page Header End-->
<div class="page-dashboard">

  <div class="row dashboard-count-cards" *ngIf="model">

    <div class="col-sm-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
      <a href="javascript:void(0)" routerLink="/admin/order/list"
        class="dashboard-count-card-items d-flex align-items-start">
        <div class="dashboard-count-card-items-icon"> <i class="fa-light fa-cart-circle-arrow-down fa-shake"
            style="--fa-animation-duration: 2s;"></i> </div>
        <div class="dashboard-count-card-items-info">
          <h1 class="mb-1">{{this.model!.NewOrder }}</h1>
          <p class="m-0">New Orders</p>
        </div>
      </a>
    </div>

    <div class="col-sm-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
      <a href="javascript:void(0)" routerLink="/admin/order/list"
        class="dashboard-count-card-items d-flex align-items-start">
        <div class="dashboard-count-card-items-icon"> <i class="fa-light fa-cart-shopping-fast"></i> </div>
        <div class="dashboard-count-card-items-info">
          <h1 class="mb-1">{{this.model!.TotalOrder }}</h1>
          <p class="m-0">Total Orders</p>
        </div>
      </a>
    </div>
    <div class="col-sm-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
      <a href="#FinancesSummary" (click)="GetFinancialRecord()" data-bs-toggle="offcanvas"
        class="dashboard-count-card-items d-flex align-items-start">
        <div class="dashboard-count-card-items-icon"> <i class="fa-light fa-money-bill-transfer"></i></div>
        <div class="dashboard-count-card-items-info">
          <h1 class="mb-1"><span></span>{{this.model!.TotalSales | currency : 'INR' : true :'1.0-0' }}
          </h1>
          <p class="m-0">Total Sales</p>
        </div>
      </a>
    </div>
    <div class="col-sm-6 col-lg-3 col-xl-3 col-xxl-3 mb-3">
      <a href="#FinancesSummary" (click)="GetFinancialRecord()" data-bs-toggle="offcanvas"
        class="dashboard-count-card-items d-flex align-items-start">
        <div class="dashboard-count-card-items-icon"> <i class="fa-light fa-money-bill-trend-up"></i> </div>
        <div class="dashboard-count-card-items-info">
          <h1 class="mb-1"><span></span>{{this.model!.NetSales | currency : 'INR' : true :'1.0-0' }}
          </h1>
          <p class="m-0">Net Sales</p>
        </div>
      </a>
    </div>
  </div>
  <div class="row charts">
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Sessions</h3>
          <h2>1,897 <span class="text-success">15% <i class="fa-light fa-chart-line-up"></i></span></h2>
        </div>
        <div class="info-card-items-body">
          <img src="assets/images/chart.png" alt="" />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Total Sales</h3>
          <h2>₹10,70,377 <span class="text-success">15% <i class="fa-light fa-chart-line-up"></i></span></h2>
        </div>
        <div class="info-card-items-body">
          <img src="assets/images/chart.png" alt="" />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Total Orders</h3>
          <h2>410 <span class="text-success">15% <i class="fa-light fa-chart-line-up"></i></span></h2>
        </div>
        <div class="info-card-items-body">
          <img src="assets/images/chart.png" alt="" />
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Conversion rate</h3>
          <h2>0.50% <span class="text-success">15% <i class="fa-light fa-chart-line-up"></i></span></h2>
        </div>
        <div class="info-card-items-body">
          <img src="assets/images/chart.png" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div class="row info-cards mt-4">
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Store Conversion</h3>
          <h2>0.08% <span class="text-danger"> <i class="fa-light fa-chart-line-down"></i></span></h2>
        </div>
        <div class="info-card-items-body">
          <div class="info-cards-list">
            <a href="javascript:void(0)" class="info-cards-list-items">
              <div class="info-cards-list-items-left">
                <i class="fa-light fa-file-certificate"></i>
                <h3>Top Selling Products</h3>
              </div>
              <div class="info-cards-list-items-right">
                <h4>40</h4>
              </div>
            </a>
            <a href="javascript:void(0)" class="info-cards-list-items">
              <div class="info-cards-list-items-left">
                <i class="fa-light fa-cart-plus"></i>
                <h3>Added to cart</h3>
              </div>
              <div class="info-cards-list-items-right">
                <h4>105</h4>
              </div>
            </a>
            <a href="javascript:void(0)" class="info-cards-list-items">
              <div class="info-cards-list-items-left">
                <i class="fa-light fa-bags-shopping"></i>
                <h3>Reached Checkout</h3>
              </div>
              <div class="info-cards-list-items-right">
                <h4>98</h4>
              </div>
            </a>
            <a href="javascript:void(0)" class="info-cards-list-items">
              <div class="info-cards-list-items-left">
                <i class="fa-light fa-cart-circle-check"></i>
                <h3>Sessions Converted</h3>
              </div>
              <div class="info-cards-list-items-right">
                <h4>5</h4>
              </div>
            </a>
            <a href="javascript:void(0)" class="info-cards-list-items">
              <div class="info-cards-list-items-left">
                <i class="fa-light fa-users"></i>
                <h3>Top Customers</h3>
              </div>
              <div class="info-cards-list-items-right">
                <h4>40</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>New vs Returning Customers</h3>
        </div>
        <div class="info-card-items-body text-center">
          <img src="assets/images/chart.png" alt="">
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Top Locations</h3>
        </div>
        <div class="info-card-items-body text-center">
          <img src="assets/images/chart.png" alt="">
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-3 mb-lg-0">
      <div class="info-card-items">
        <div class="info-card-items-header">
          <h3>Payment Method</h3>
        </div>
        <div class="info-card-items-body text-center">
          <img src="assets/images/chart.png" alt="">
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Finances Summary  Drawer Start -->
<div class="offcanvas offcanvas-end pagesidebar-medium with-filter-sidebar" tabindex="-1" id="FinancesSummary"
  data-bs-keyboard="false">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title"> Finances Summary</h5>
    <div class="page-header-action justify-content-center">
      <a href="javascript:void(0)" data-title="Download Excel" class="page-header-action-items"><i
          class="fa-light fa-file-excel"></i></a>
      <div class="page-filter-form me-2">
        <div class="mini-filter-form-items date-picker-icon m-0">
          <date-range-picker [(ngModel)]="dateValue" (onChange)="GetFinancialRecord()"></date-range-picker>
          <i class="fa-light fa-calendar-days"></i>
        </div>
        <div class="mini-filter-form-items">
          <select class="form-select" [(ngModel)]="recordType" (change)="GetFinancialRecord()">
            <option [value]="recordTypeEnum.All" [selected]="recordTypeEnum.All == recordType">All </option>
            <option [value]="recordTypeEnum.BtoC" [selected]="recordTypeEnum.BtoC == recordType">B2C</option>
            <option [value]="recordTypeEnum.BtoB" [selected]="recordTypeEnum.BtoB == recordType">B2B </option>
          </select>
        </div>
      </div>

      <a href="javascript:void(0)" class="offcanvas-close" (click)="getCount()" #btnClose data-bs-dismiss="offcanvas">
        <i class="fa-light fa-xmark"></i> </a>
    </div>
  </div>
  <div class="offcanvas-body offcanvas-body-fix-scroll pt-1">
    <div class="content-box summary-info">
      <div class="row">
        <div class="col-lg-12">
          <div class="summary-info-card">
            <ul>
              <li> <span>Total Sales</span> <strong>{{ totalAmount | currency : 'INR' : true :'1.0-0'}} </strong>
              </li>
            </ul>
            <table class="custom-mat-table">
              <tr>
                <th>Status </th>
                <th>Orders </th>
                <th class="text-end">Amount </th>
              </tr>
              <tr *ngFor="let itm of financialModel">
                <td>{{itm.Status}}</td>
                <td>{{itm?.TotalOrders}}</td>
                <td class="text-end">{{itm?.TotalAmount ?? 0| currency : 'INR' : true :'1.0-0'}}</td>
              </tr>
              <tr>
                <td><strong> Total </strong></td>
                <td> <strong> {{totalOrder}} </strong></td>
                <td class="text-end"><strong>{{ totalAmount | currency : 'INR' : true :'1.0-0'}} </strong></td>
              </tr>
            </table>
            <ul>
              <li> <span>Net Sales</span> <strong>₹{{ netAmount | currency : 'INR' : true :'1.0-0'}}</strong> </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Finances Summary  Drawer Start -->
