<div class="sidebar">
  <div id="accordionExample" class="sidebar-height ">

    <div class="sidebar-item" routerLinkActive="menu-active" [routerLinkActiveOptions]="{exact: true}">
      <button class="sidebar-item-button arrow-none collapsed" routerLink="/admin" data-bs-toggle="collapse"
        data-bs-target="#SidebarDashboard">
        <i class="fa-light fa-gauge-high"></i> <span class="menu-name"> Dashboard </span>
      </button>
      <div id="SidebarDashboard" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      </div>
    </div>

    <div class="sidebar-item" routerLinkActive="menu-active" routerLink="./product/list"
      [routerLinkActiveOptions]="{exact: true}">
      <button class="sidebar-item-button arrow-none collapsed" data-bs-toggle="collapse"
        data-bs-target="#SidebarProducts">
        <i class="fa-light fa-cube"></i>  <span class="menu-name"> Products </span>
      </button>
      <div id="SidebarProducts" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      </div>
    </div>

    <div class="sidebar-item" routerLinkActive="menu-active" routerLink="./order/list"
      [routerLinkActiveOptions]="{exact: true}">
      <button class="sidebar-item-button  arrow-none collapsed" data-bs-toggle="collapse"
        data-bs-target="#SidebarItemOrders">
        <i class="fa-light fa-cart-shopping"></i> <span class="menu-name"> Orders </span> <span
          class="menu-order-count fa-fade" *ngIf="_dashboardService?.dashboardCount?.NewOrder">{{_dashboardService?.dashboardCount?.NewOrder}}</span>
      </button>
      <div id="SidebarItemOrders" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      </div>
    </div>


    <div class="sidebar-item master-items" routerLinkActive="menu-active">
      <button class="sidebar-item-button collapsed" type="button" data-bs-toggle="collapse"
        data-bs-target="#SidebarItemMaster">
        <i class="fa-light fa-recycle"></i>  <span class="menu-name"> Masters </span>
      </button>
      <div id="SidebarItemMaster" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <ul class="sidebar-submenu">
          <li *ngFor="let itm of dropDown.ddlLookupTypeMaster" routerLinkActive="sub-menu-active">
            <a href="javascript:void(0)" routerLink="./master/{{itm.Text}}/{{itm.Value}}">
              <i class="bi bi-window"></i> {{itm.Text| titlecase}}
            </a>
          </li>

          <li *ngFor="let itm of dropDown.ddlGeneralEntryCategory" routerLinkActive="sub-menu-active">
            <a href="javascript:void(0)" routerLink="./master/forms/{{itm.Text.replace('-','~')}}/{{itm.Value}}">
              <i class="bi bi-window"></i> {{itm.Text| titlecase}}
            </a>
          </li>

          <li routerLinkActive="sub-menu-active">
            <a routerLink="./master/general-entry-category"><i class="bi bi-window"></i> General Entry
              Category
            </a>
          </li>
        </ul>
      </div>
    </div>


    <div class="sidebar-item" routerLinkActive="menu-active" [routerLinkActiveOptions]="{exact: true}" >
      <button class="sidebar-item-button arrow-none collapsed" routerLink="./report"  data-bs-toggle="collapse"
        data-bs-target="#SidebarReport">
        <i class="fa-light fa-chart-column"></i> <span class="menu-name"> Reports </span>
      </button>
      <div id="SidebarReport" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      </div>
    </div>


    <div class="sidebar-item" routerLinkActive="menu-active" [routerLinkActiveOptions]="{exact: true}">
      <button class="sidebar-item-button  arrow-none collapsed" data-bs-toggle="collapse"
        data-bs-target="#SidebarCoupon">
        <i class="fa-light fa-badge-percent"></i>  <span class="menu-name"> Coupon </span>
      </button>
      <div id="SidebarCoupon" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      </div>
    </div>


    <div class="sidebar-item" routerLinkActive="menu-active">
      <button class="sidebar-item-button collapsed" type="button" data-bs-toggle="collapse"
        data-bs-target="#SidebarItemManageStore">
        <i class="fa-light fa-shop"></i>  <span class="menu-name"> Management </span>
      </button>
      <div id="SidebarItemManageStore" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <ul class="sidebar-submenu">
          <li routerLinkActive="sub-menu-active" [routerLinkActiveOptions]="{exact: true}"> <a href="javascript:void(0)"
              routerLink="./user/list"><i class="fa-light fa-user"></i> Users</a>
          </li>
          <li routerLinkActive="sub-menu-active" [routerLinkActiveOptions]="{exact: true}"> <a href="javascript:void(0)"
              routerLink="./customer/list"><i class="fa-light fa-users"></i> Customers</a>
          </li>
          <li routerLinkActive="sub-menu-active" [routerLinkActiveOptions]="{exact: true}"> <a href="javascript:void(0)"
              routerLink="./master/cms-page"> <i class="fa-light fa-memo"></i> CMS Page</a>
          </li>

          <!-- <li routerLinkActive="sub-menu-active" [routerLinkActiveOptions]="{exact: true}"> <a href="javascript:void(0)" routerLink="./master/general-entry"> <i class="fa-light fa-nfc-pen"></i> General Entry</a>
          </li> -->
        </ul>
      </div>
    </div>

    <div class="sidebar-footer">
      <div class="admin-version">App Version : <span>{{version}}</span></div>
    </div>

  </div>
</div>
