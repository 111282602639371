import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService, LoginUserDetailModel } from '../../../Shared/Helper/auth.service';
import { SettingViewModel } from 'src/app/Shared/Services/Common/setting.service';
import { CommonService } from 'src/app/Shared/Services/Common/common.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  themeTitle = '';
  @Output() SetTheme = new EventEmitter<string>();
  get appSetting(): SettingViewModel { return this._commonService?.setting?.appSetting ?? {} };
  get auth() { return this._authService?.GetUserDetail() ?? undefined }

  constructor(public readonly _authService: AuthService, private readonly _commonService: CommonService) { }

  ngOnInit(): void {
    setTimeout(() => {
      //   this.changeTheme();
      this.SidebarBtn();
    }, 10);

  }
  avatar() {
    return this.auth?.ProfilePhoto ?? ''
  }

  changeTheme(themeName: string = '', themeTitle = '') {
    let theme = localStorage.getItem('currentTheme') != null ? localStorage.getItem('currentTheme') : 'theme-default';

    if (theme == themeName) {
      this.SetTheme.emit('theme-default');
      this.themeTitle = themeTitle;

    } else {
      this.themeTitle = 'Light mode';
      this.SetTheme.emit(themeName);
    }

  }

  SidebarBtn() {
    $(".sidebar-menu-btn").click(function () {
      $("body").toggleClass("sidebar-open");
    });

    $("ul.sidebar-submenu a, .sidebar-item-button.arrow-none").click(function () {
      $("body").removeClass("sidebar-open");
    });
  }
}


