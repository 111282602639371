import { Directive, ElementRef, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Directive({
  selector: '[RestrictInput]'
})
export class RestrictInputDirective {

  private regex: RegExp = /^[a-zA-Z0-9-]*$/;

  constructor(private el: ElementRef,private readonly toast: ToastrService) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow: backspace, tab, enter, and dash
    if (event.key === 'Backspace' || event.key === 'Tab' || event.key === 'Enter' || event.key === '-') {
      return;
    }

    // If the key is not a valid character, prevent the input
    if (event.key.match(this.regex) === null) {
      event.preventDefault();
      this.toast.warning('Invalid char, Only - and alphanumeric character allowed', 'Opps');

    }
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    // Check for consecutive dashes
    if (value.includes('--')) {
      input.value = value.replace(/--+/g, '-'); // Replace consecutive dashes with a single dash
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData  as DataTransfer;
    const pastedData = clipboardData.getData('text');

    // Prevent pasting if it contains invalid characters
    if (!this.regex.test(pastedData) || pastedData.includes('--')) {
      event.preventDefault();
      this.toast.warning('Invalid char, Only - and alphanumeric character allowed', 'Opps');

    }
  }

}
