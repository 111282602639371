import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
import { SecurityService } from './security.service';
import { AuthService } from './auth.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private _loaderService: LoaderService, private _commonService: SecurityService, private readonly _auth: AuthService) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loaderService.show();
    const tm = new Date().getTime().toString()
    let Token = this._commonService.getStorage("authToken") != null ? this._commonService.getStorage("authToken") : null;
    if (Token != null) {
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + Token,
        },
        setParams: {
          timestamp: tm
        }
      });
    } else {
      req = req.clone({

        setParams: {
          timestamp: tm
        }
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 Unauthorized error - logout the user
          this._auth.LogOut();
        }
        return throwError(error); // Re-throw the error to propagate it
      }),

      finalize(() => this._loaderService.hide()));

  }
}
