<app-no-records *ngIf="totalRecords==0 && (!indexModel.Search || indexModel.Search=='') else ShowRecord"
  [data]="noRecordData" (onClick)="addRecord()"></app-no-records>
<button #addNewRecord data-bs-toggle="offcanvas" href="#PageSidebar" class="page-header-action-items"
  [hidden]="true" data-title="{{'Add '+page.replace('~',' ') }}"></button>
<ng-template #ShowRecord>
  <!-- Page Header Start-->
  <div class="page-header">
    <div class="page-header-title">
      {{page.replace('~',' ')}} List
      <!-- <span class="page-item-count">{{ totalRecords }}</span> -->
    </div>
    <div class="page-header-action">
      <button (click)="addRecord()" class="page-header-action-items" data-title="{{'Add '+page.replace('~',' ') }}">
        <i class="fa-regular fa-plus"></i></button>
        <div class="page-filter-form">
          <div class="mini-filter-form-items">
            <span class="mini-filter-count">{{ totalRecords }}</span>
            <select class="form-select" (change)="GetListData($event)">
                <option [value]="recordTypeEnum.All" [selected]="recordTypeEnum.All == recordType">All  </option>
                <option [value]="recordTypeEnum.BtoC" [selected]="recordTypeEnum.BtoC == recordType">B2C  </option>
                <option [value]="recordTypeEnum.BtoB" [selected]="recordTypeEnum.BtoB == recordType">B2B  </option>
            </select>

        </div>
      </div>

      <div class="page-header-action-search">
        <button type="button" (click)="onSearch()" class="btn-filter-search"><i
            class="fa-light fa-magnifying-glass"></i></button>
        <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
          class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search" />
      </div>
    </div>
  </div>
  <!-- Page Header End-->

  <!-- Main Page Content Start -->

  <!-- <div class="tab-btns mb-2 mt-3">
    <a href="javascript:void(0)"
      (click)="geProductList(recordTypeEnum.All)" [ngClass]="{'active':recordTypeEnum.All ==this.recordType}" class="tab-btns-items">All <span *ngIf="recordTypeEnum.All ==this.recordType" class="tab-counting"> {{totalRecords}} </span></a>
    <a href="javascript:void(0)" [ngClass]="{'active':recordTypeEnum.BtoC ==this.recordType}"
      (click)="geProductList(recordTypeEnum.BtoC)" class="tab-btns-items "> B2C <span class="tab-counting" *ngIf="recordTypeEnum.BtoC ==this.recordType"> {{totalRecords}} </span></a>
    <a href="javascript:void(0)" [ngClass]="{'active':recordTypeEnum.BtoB ==this.recordType}"
      (click)="geProductList(recordTypeEnum.BtoB)" class="tab-btns-items">B2B <span class="tab-counting" *ngIf="recordTypeEnum.BtoB ==this.recordType"> {{totalRecords}} </span></a>
  </div> -->


  <div class="table-responsive table-shadow">

    <table class="custom-mat-table media-table" mat-table [dataSource]="dataSource" matSort role="grid"
      (matSortChange)="sortData($event)">

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
      </ng-container>


      <ng-container matColumnDef="ImagePath">
        <th mat-header-cell *matHeaderCellDef style="width: 250px;">Image</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="table-list-img">
            <a [href]="element.ImagePath" [attr.data-lightbox]="'Seller-Portal'">
              <img [src]="element.ImagePath" ViewImage   [isHorizontal]="true">
            </a>
          </div>
        </td>
      </ng-container>


      <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">

        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column!.Text}} </th>
        <td mat-cell *matCellDef="let element">
          <div [innerHTML]="element[column!.Value]"></div>

        </td>
      </ng-container>

      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element" class="table-action-btn">
          <a (click)="OnActiveStatus(element!.Id)" data-title="Update Active Status">
            <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
          </a>

        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th width="120" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row" class="table-action-btn">
          <a href="javascript:void(0)" class="table-action-btn-view"
            (click)="selectedRecord=row.Id;isShowForm=true;ViewMode='Detail'" data-title="View Detail"
            data-bs-toggle="offcanvas" href="#PageSidebar"><i class="fa-light fa-eye"></i>
          </a>
          <a href="javascript:void(0)" class="table-action-btn-edit"
            (click)="selectedRecord=row.Id;isShowForm=true; ViewMode='Edit'" data-title="Edit"
            data-bs-toggle="offcanvas" href="#PageSidebar" data-title="Edit"><i class="fa-light fa-pen"></i>
          </a>
          <a class="table-action-btn-delete" (click)="updateDeleteStatus(row.Id)" data-title="Delete">
            <i class="fa-light fa-trash-can"></i>
          </a>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="no-record-found text-center" *ngIf="totalRecords==0">
      <img src="assets/images/record-not-found.svg" alt="" />
      <p class="mb-0"> Sorry, No Record Found </p>
    </div>
  </div>

  <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">
    <mat-paginator #paginator [length]="totalRecords" [pageIndex]="(indexModel.Page-1)" [pageSize]="indexModel.PageSize"
      (page)="onPaginateChange($event)" showFirstLastButtons *ngIf="totalRecords!=0">
    </mat-paginator>
  </ul>
  <!-- Main Page Content End -->
</ng-template>

<div class="offcanvas offcanvas-end pagesidebar-medium" tabindex="-1" id="PageSidebar" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title"><span [ngSwitch]="ViewMode">
        <p *ngSwitchCase="'Add'" class="m-0">
          Add {{page.replace('~',' ')}}
        </p>
        <p *ngSwitchCase="'Edit'" class="m-0">
          Edit {{page.replace('~',' ')}}
        </p>
        <p *ngSwitchDefault class="m-0">
          {{page.replace('~',' ')}} Detail
        </p>
      </span></h5>
    <div class="d-flex align-items-center">
      <button *ngIf=" ViewMode!='Add'" (click)="changeViewMode()" class="page-header-action-items"
        data-title="{{ViewMode}}"> <i class=""
          [ngClass]="{'fa-light fa-eye':ViewMode=='Edit' ,'fa-light fa-pen':ViewMode=='Detail' }"></i></button>
      <a href="javascript:void(0)" class="offcanvas-close" #btnClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
    </div>
  </div>
  <div class="offcanvas-body offcanvas-body-fix-scroll pt-3">
    <app-general-entry-master-add-edit *ngIf="isShowForm && (ViewMode=='Edit'|| ViewMode=='Add')" [typeData]="typeData"
      (OnClose)="closePopup()" (OnSave)="reloadData($event)" [Id]="selectedRecord"></app-general-entry-master-add-edit>

    <app-general-entry-master-detail *ngIf="isShowForm && ViewMode=='Detail'" [refreshData]="isDataRefresh"
      [typeData]="typeData" [Id]="selectedRecord"></app-general-entry-master-detail>

  </div>
</div>
