<app-no-records *ngIf="totalRecords==0 && (!indexModel.Search || indexModel.Search=='') else ShowRecord"
  [data]="noRecordData"></app-no-records>
<ng-template #ShowRecord>
  <div>

    <!-- Page Header Start-->
    <div class="page-header">
      <div class="page-header-title">
        {{pageName}}
      </div>
      <div class="page-header-action">
        <button (click)="selectedRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas" href="#PageSidebar"
          title="Add" class="page-header-action-items"> <i class="fa-regular fa-plus"></i></button>
        <div class="page-header-action-search">
          <button type="button" (click)="onSearch()" class="btn-filter-search"><i
              class="fa-light fa-magnifying-glass"></i></button>
          <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
            class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search" />
        </div>
      </div>
    </div>
    <!-- Page Header End-->

    <!-- Main Page Content Start -->

    <div class="table-responsive table-shadow">
      <table class="custom-mat-table" mat-table [dataSource]="dataSource" matSort role="grid"
        (matSortChange)="sortData($event)">
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef style="width: 50px;">#</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
        </ng-container>

        <ng-container matColumnDef="ImagePath">
          <th mat-header-cell *matHeaderCellDef style="width: 120px;">Image</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            <div class="table-list-img d-flex align-items-center justify-content-center">
              <a [href]="element.ImagePath" [attr.data-lightbox]="'Seller-Portal'">
                <img [src]="element.ImagePath" ViewImage >
              </a>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:130px;"> {{column!.Text}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column!.Value]}} </td>
        </ng-container>

        <ng-container matColumnDef="Flags">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Flags </th>
          <td mat-cell *matCellDef="let element" class="gen-entry-flags table-data-tags">
            <ng-container *ngIf="!element.IsSystemEntry; else viewFlagOnly">
              <a (click)="onFlagStatus(element!.Id,'IsShowInMain')" data-title="Update Status">
                <span [ngClass]="element!.IsShowInMain ? 'active':'default'">
                  <i class="fa-regular fa-check me-1"></i>&nbsp;Show In Home
                </span>
              </a>

              <a (click)="onFlagStatus(element!.Id,'IsShowDataInMain')" data-title="Update Status">
                <span [ngClass]="element!.IsShowDataInMain ? 'active': 'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Show data in Home</span>
              </a>

              <a (click)="onFlagStatus(element!.Id,'IsSingleEntry')" data-title="Update Status">
                <span [ngClass]="element!.IsSingleEntry ? 'active':'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Single Entry</span>
              </a>

              <a (click)="onFlagStatus(element!.Id,'IsShowThumbnail')" data-title="Update Status">
                <span [ngClass]="element!.IsShowThumbnail ? 'active':'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Show Thumbnail</span>
              </a>

              <a (click)="onFlagStatus(element!.Id,'IsShowUrl')" data-title="Update Status">
                <span [ngClass]="element!.IsShowUrl ? 'active':'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Show URL</span>
              </a>
            </ng-container>
            <ng-template #viewFlagOnly>
              <a data-title="Update Status">
                <span [ngClass]="element!.IsShowInMain ? 'active':'default'"><i
                    class="fa-regular fa-check me-1 "></i>&nbsp;Show in Home</span>
              </a>

              <a data-title="Update Status">
                <span [ngClass]="element!.IsShowDataInMain ? 'active': 'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Show data in Home</span>
              </a>

              <a data-title="Update Status">
                <span [ngClass]="element!.IsSingleEntry ? 'active':'default'"><i
                    class="fa-regular fa-check me-1"></i>&nbsp;Single
                  Entry</span>
              </a>

              <a data-title="Update Status">
                <span [ngClass]="element!.IsShowThumbnail ? 'active':'default'"><i
                    class="fa-regular fa-check me-1 "></i>&nbsp;Show Thumbnail</span>
              </a>

              <a data-title="Update Status">
                <span [ngClass]="element!.IsShowUrl ? 'active':'default'"><i
                    class="fa-regular fa-check me-1 "></i>&nbsp;Show URL</span>
              </a>
            </ng-template>

          </td>
        </ng-container>

        <ng-container matColumnDef="IsActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50px;">Status </th>
          <td mat-cell *matCellDef="let element" class="table-action-btn">

            <ng-container *ngIf="element.IsSystemEntry;else editStatus">
              <a data-title="Active Status">
                <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
                <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
              </a>
            </ng-container>

            <ng-template #editStatus>
              <a (click)="OnActiveStatus(element!.Id)" data-title="Update Active Status">
                <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
                <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
              </a>
            </ng-template>


          </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef style="width: 120px;"> Action </th>
          <td mat-cell *matCellDef="let row" class="table-action-btn">
            <a href="javascript:void(0)" class="table-action-btn-view" (click)="selectedRecord=row.Id;ViewMode='Detail'"
              data-bs-toggle="offcanvas" href="#PageSidebar" data-title="View Detail"><i
                class="fa-light fa-eye"></i>
            </a>
            <a *ngIf="!row.IsSystemEntry" (click)="selectedRecord=row.Id; ViewMode='Edit'" data-title="Edit"
              data-bs-toggle="offcanvas" href="#PageSidebar" class="table-action-btn-edit"><i
                class="fa-light fa-pen"></i>
            </a>

            <a *ngIf="!row.IsSystemEntry" class="table-action-btn-delete" (click)="updateDeleteStatus(row.Id)"
              data-title="Delete"> <i class="fa-light fa-trash-can"></i> </a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="no-record-found text-center" *ngIf="totalRecords==0">
        <img src="assets/images/record-not-found.svg" alt="" />
        <p class="mb-0"> Sorry, No Record Found </p>
      </div>
    </div>

    <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">
      <mat-paginator #paginator [length]="totalRecords" [pageSize]="indexModel.PageSize"
        [pageIndex]="(indexModel.Page-1)" (page)="onPaginateChange($event)" showFirstLastButtons
        *ngIf="totalRecords!=0">
      </mat-paginator>
    </ul>
  </div>

  <!-- Main Page Content End -->

  <div class="offcanvas offcanvas-end pagesidebar-medium" tabindex="-1" id="PageSidebar" data-bs-keyboard="false">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title"><span [ngSwitch]="ViewMode">
          <p *ngSwitchCase="'Add'" class="m-0">
            Add General Entry Category
          </p>
          <p *ngSwitchCase="'Edit'" class="m-0">
            Edit General Entry Category
          </p>
          <p *ngSwitchDefault class="m-0">
            General Entry Category Detail
          </p>
        </span></h5>
      <div class="d-flex align-items-center">
        <button *ngIf="!IsSystemEntry && ViewMode!='Add'" (click)="changeViewMode()"
          class="page-header-action-items" title="{{ViewMode}}"> <i class=""
            [ngClass]="{'fa-light fa-eye':ViewMode=='Edit' ,'fa-light fa-pen':ViewMode=='Detail' }"></i></button>
        <a href="javascript:void(0)" class="offcanvas-close"  #btnClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
      </div>
    </div>
    <div class="offcanvas-body offcanvas-body-fix-scroll pt-3">
      <app-general-entry-category-master-add-edit [ngClass]="{'d-none':  ViewMode=='Detail'}"
        (OnSave)="reloadData($event)" [Id]="selectedRecord"></app-general-entry-category-master-add-edit>
      <app-general-entry-category-master-detail [refreshData]="isDataRefresh"
        [ngClass]="{'d-none': ViewMode=='Edit'|| ViewMode=='Add'}"
        [Id]="selectedRecord"></app-general-entry-category-master-detail>

    </div>
  </div>
</ng-template>
