import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';


@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  bsConfig?: Partial<BsDaterangepickerConfig>;
  ranges: IRange[] = [
    {
      value: [
        new Date(new Date().getFullYear(), 0, 1),
        new Date(new Date().getFullYear(), 11, 31)
      ],
      label: 'Current Year'
    },
    {
      value: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      ],
      label: 'Current Month'
    },
    {
      value: [
        new Date(new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear(), 3, 1),
        new Date(new Date().getMonth() < 3 ? new Date().getFullYear() : new Date().getFullYear() + 1, 2, 31)
      ],
      label: 'Financial Year'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 90)), new Date()],
      label: 'Last 90 Days'
    },
    {
      value: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        new Date(new Date().getFullYear(), new Date().getMonth(), 0)
      ],
      label: 'Last Month'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
      label: 'Last 30 Days'
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      label: 'Last 7 Days'
    },
    // {
    //   value: [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
    //   label: 'Yesterday'
    // },
    {
      value: [new Date(new Date().setDate(new Date().getDate())), new Date()],
      label: 'Today'
    },


  ];

  //#region Two bindings
  private _value!: Date[];

  @Input('ngModel')
  get ngModel(): Date[] {
    return this._value;
  }

  set ngModel(value: any) {
    this._value = value;
    this.ngModelChange.emit(value); // Emit the new value when it changes
  }
  @Output() ngModelChange = new EventEmitter<Date[]>(); // Output property to emit changes


  //#endregion
  @Output() onChange = new EventEmitter<any>(); // Output property to emit changes

  constructor() { }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-default', ranges: this.ranges, showPreviousMonth: true, rangeInputFormat: 'DD MMM YYYY', dateInputFormat: 'DD MMM YYYY', showWeekNumbers: false });

  }
  onValueChange(value: any) {
    this.onChange.emit(value); // Emit the new value when it changes
  }


}
interface IRange {
  value: Date[];
  label: string;
}
