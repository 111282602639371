
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

export class API_Url {

  //#region <<Login>>
  public static Login_Api = `${environment.apiEndPoint}account/Login`;
  public static Logout_Api = `${environment.apiEndPoint}account/Logout`;
  public static GetEncryptedText_Api = `${environment.apiEndPoint}account/GetEncryptedText`;

  //#endregion

  //#region <<Common >>
  public static DropDown_Api = `${environment.apiEndPoint}Dropdown/GetDropDown`;
  public static FilterDropDown_Api = `${environment.apiEndPoint}Dropdown/GetFilterDropDown`;
  public static MultipleFilterDropDown_Api = `${environment.apiEndPoint}Dropdown/GetMultipleFilterDropDown`;

  //#endregion

  //#region <<User Role>>
  public static UserRole_Dropdown_Api = `${environment.apiEndPoint}admin/UserRole/Roles`;
  public static UserRoleList_Api = `${environment.apiEndPoint}admin/UserRole/Get`;
  public static UserRoleDetail_Api = `${environment.apiEndPoint}admin/UserRole/get/`;
  public static UserRoleAddUpdate_Api = `${environment.apiEndPoint}admin/UserRole/post`;
  public static UserRoleCheckRoleExist_Api = `${environment.apiEndPoint}admin/UserRole/CheckRoleExist/`;
  public static UserRoleChangeActiveStatus_Api = `${environment.apiEndPoint}admin/UserRole/ChangeActiveStatus/`;
  public static UserRoleDelete_Api = `${environment.apiEndPoint}admin/UserRole/delete/`;
  //#endregion

  //#region <<LookupTypeMaster>>
  public static LookupTypeMasterList_Api = `${environment.apiEndPoint}admin/LookupTypeMaster/get`;
  public static LookupTypeMasterDetail_Api = `${environment.apiEndPoint}admin/LookupTypeMaster/get/`;
  public static LookupTypeMasterAddUpdate_Api = `${environment.apiEndPoint}admin/LookupTypeMaster/post`;
  public static LookupTypeMasterChangeActiveStatus_Api = `${environment.apiEndPoint}admin/LookupTypeMaster/ChangeActiveStatus/`;
  public static LookupTypeMasterDelete_Api = `${environment.apiEndPoint}admin/LookupTypeMaster/delete/`;
  //#endregion

  //#region <<LookupMaster>>
  public static LookupMasterList_Api = `${environment.apiEndPoint}admin/LookupMaster/get`;
  public static LookupMasterDetail_Api = `${environment.apiEndPoint}admin/LookupMaster/get/`;
  public static LookupMasterAddUpdate_Api = `${environment.apiEndPoint}admin/LookupMaster/Save`;
  public static LookupMasterChangeActiveStatus_Api = `${environment.apiEndPoint}admin/LookupMaster/ChangeActiveStatus/`;
  public static LookupMasterDelete_Api = `${environment.apiEndPoint}admin/LookupMaster/delete/`;
  public static LookupMasterCheckURLDuplicateApi = `${environment.apiEndPoint}admin/LookupMaster/CheckURLDuplicate`;



  //#endregion

  //#region <<SubLookupMaster>>
  public static SubLookupMasterList_Api = `${environment.apiEndPoint}admin/SubLookupMaster/get`;
  public static SubLookupMasterDetail_Api = `${environment.apiEndPoint}admin/SubLookupMaster/get/`;
  public static SubLookupMasterAddUpdate_Api = `${environment.apiEndPoint}admin/SubLookupMaster/Save`;
  public static SubLookupMasterChangeActiveStatus_Api = `${environment.apiEndPoint}admin/SubLookupMaster/ChangeActiveStatus/`;
  public static SubLookupMasterDelete_Api = `${environment.apiEndPoint}admin/SubLookupMaster/delete/`;
  public static SubLookupMasterCheckURLDuplicateApi = `${environment.apiEndPoint}admin/SubLookupMaster/CheckURLDuplicate`;

  //#endregion

  //#region <<User Setting>>
  public static GetUserProfileApi = `${environment.apiEndPoint}admin/UserSetting/GetUserProfile/`;
  public static UserUpdateProfileApi = `${environment.apiEndPoint}admin/UserSetting/UpdateProfile`;
  public static UserApproveStatusApi = `${environment.apiEndPoint}admin/UserSetting/UpdateApproveStatus/`;
  public static GetUserAvailableAreaApi = `${environment.apiEndPoint}admin/UserSetting/GetUserAvailableAreaForRolebyPinCode/`;
  public static SetUserAvailabilityApi = `${environment.apiEndPoint}admin/UserSetting/SetUserAvailability`;
  public static GetUserAvailabilityListApi = `${environment.apiEndPoint}admin/UserSetting/GetUserAvailabilityList/`;
  public static DeleteDocumentFileApi = `${environment.apiEndPoint}admin/UserSetting/DeleteDocumentFile/`;


  //#endregion

  //#region  << Product  >>
  public static Product_List_Api = `${environment.apiEndPoint}admin/ProductMaster/GetList`;
  public static Product_AddUpdate_Api = `${environment.apiEndPoint}admin/ProductMaster/Post`;
  public static Product_Delete_Api = `${environment.apiEndPoint}admin/ProductMaster/Delete/`;
  public static Product_ActiveStatus_Api = `${environment.apiEndPoint}admin/ProductMaster/ChangeActiveStatus/`;
  public static Product_Detail_Api = `${environment.apiEndPoint}admin/ProductMaster/Get`;
  public static ProductFile_Delete_Api = `${environment.apiEndPoint}admin/ProductMaster/DeleteProductFile/`;
  public static ProductIsSKUExist_Api = `${environment.apiEndPoint}admin/ProductMaster/IsSKUExist/`;
  public static ProductImportFile_Api = `${environment.apiEndPoint}admin/ProductMaster/DownloadExcel`;
  public static ProductImportProductExcel_Api = `${environment.apiEndPoint}admin/ProductMaster/ImportProductExcel`;



  //#endregion


  //#region  << User  >>
  public static User_List_Api = `${environment.apiEndPoint}admin/UserMaster/Get`;
  public static User_Detail_Api = `${environment.apiEndPoint}admin/UserMaster/Get/`;
  public static User_AddUpdate_Api = `${environment.apiEndPoint}admin/UserMaster/Post`;
  public static User_Delete_Api = `${environment.apiEndPoint}admin/UserMaster/Delete/`;
  public static User_ActiveStatus_Api = `${environment.apiEndPoint}admin/UserMaster/ActiveStatus/`;


  //#endregion

  //#region  << Customer  >>
  public static Customer_List_Api = `${environment.apiEndPoint}admin/Customer/Get`;
  public static Customer_Detail_Api = `${environment.apiEndPoint}admin/Customer/Get/`;
  public static Customer_AddUpdate_Api = `${environment.apiEndPoint}admin/Customer/Post`;
  public static Customer_Delete_Api = `${environment.apiEndPoint}admin/Customer/Delete/`;
  public static Customer_ActiveStatus_Api = `${environment.apiEndPoint}admin/Customer/ActiveStatus/`;


  //#endregion


  //#region <<CMSPage>>
  public static CMSPageList_Api = `${environment.apiEndPoint}admin/CMSPage/get`;
  public static CMSPageDetail_Api = `${environment.apiEndPoint}admin/CMSPage/Get/`;
  public static CMSPageAddUpdate_Api = `${environment.apiEndPoint}admin/CMSPage/Save`;
  public static CMSContentChangeActiveStatus_Api = `${environment.apiEndPoint}admin/CMSPage/ChangeActiveStatus/`;
  public static CMSContentDelete_Api = `${environment.apiEndPoint}admin/CMSPage/delete/`;
  //#endregion

  //#region <<GeneralEntryCategory>>
  public static GeneralEntryCategoryList_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/get`;
  public static GeneralEntryCategoryDetail_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/get/`;
  public static GeneralEntryCategoryAddUpdate_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/Save`;
  public static GeneralEntryCategoryChangeActiveStatus_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/ChangeActiveStatus/`;
  public static GeneralEntryCategoryChangeFlagStatus_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/ChangeFlagStatusUpdate/`;

  public static GeneralEntryCategoryDelete_Api = `${environment.apiEndPoint}admin/GeneralEntryCategory/delete/`;
  //#endregion

  //#region <<GeneralEntrySubCategory>>
  public static GeneralEntrySubCategoryList_Api = `${environment.apiEndPoint}admin/GeneralEntrySubCategory/get`;
  public static GeneralEntrySubCategoryDetail_Api = `${environment.apiEndPoint}admin/GeneralEntrySubCategory/get/`;
  public static GeneralEntrySubCategoryAddUpdate_Api = `${environment.apiEndPoint}admin/GeneralEntrySubCategory/Save`;
  public static GeneralEntrySubCategoryChangeActiveStatus_Api = `${environment.apiEndPoint}admin/GeneralEntrySubCategory/ChangeActiveStatus/`;
  public static GeneralEntrySubCategoryDelete_Api = `${environment.apiEndPoint}admin/GeneralEntrySubCategory/delete/`;
  //#endregion


  //#region <<GeneralEntry>>
  public static GeneralEntryList_Api = `${environment.apiEndPoint}admin/GeneralEntry/get`;
  public static GeneralEntryDetail_Api = `${environment.apiEndPoint}admin/GeneralEntry/get/`;
  public static GeneralEntryAddUpdate_Api = `${environment.apiEndPoint}admin/GeneralEntry/Save`;
  public static GeneralEntryChangeActiveStatus_Api = `${environment.apiEndPoint}admin/GeneralEntry/ChangeActiveStatus/`;
  public static GeneralEntryDelete_Api = `${environment.apiEndPoint}admin/GeneralEntry/delete/`;
  public static GeneralEntryItems_Delete_Api = `${environment.apiEndPoint}admin/GeneralEntry/DeleteGeneralEntryItems/`;

  //#endregion

  //#region <<Customer Registration>>
  public static Customer_Registration_Api = `${environment.apiEndPoint}Customer/RegisterCustomer/`;
  // #endregion

  //#region  <<  VendorOrder  >>
  public static VendorOrder_GetOrderDetail_Api = `${environment.apiEndPoint}Vendor/VendorOrders/Get`;
  public static VendorOrder_GetList_Api = `${environment.apiEndPoint}Vendor/VendorOrders/Get`;
  public static VendorOrder_GetInvoiceFile_Api = `${environment.apiEndPoint}Vendor/VendorOrders/GetInvoiceFile`;
  public static VendorOrder_DeleteOrder_Api = `${environment.apiEndPoint}Vendor/VendorOrders/Delete`;

  //#endregion

  //#region  <<  Shipping  >>
  public static Shipping_PincodeServiceability_Api = `${environment.apiEndPoint}Vendor/Shipping/PincodeServiceability`;
  public static Shipping_BookShippingOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/BookShippingOrder`;
  public static Shipping_RateCalculator_Api = `${environment.apiEndPoint}Vendor/Shipping/RateCalculator`;
  public static Shipping_AssignShippingOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/AssignShippingOrder`;
  public static Shipping_SchedulePickup_Api = `${environment.apiEndPoint}Vendor/Shipping/SchedulePickup`;
  public static Shipping_DispatchOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/DispatchOrder`;
  public static Shipping_CompleteOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/CompleteOrder`;

  public static Shipping_TrackOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/TrackOrder`;
  public static Shipping_CGetOrderLabel_Api = `${environment.apiEndPoint}Vendor/Shipping/GetOrderLabel`;

  public static Shipping_CancelOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/CancelOrder`;
  public static Shipping_ReInitiateOrder_Api = `${environment.apiEndPoint}Vendor/Shipping/ReInitiateOrder`;




  //#endregion

  //#region  <<  Dashboard  >>
  public static Dashboard_GetCount_Api = `${environment.apiEndPoint}Dashboard/Dashboard/GetCount`;
  //#endregion

  //#region  <<  Report  >>
  public static Report_GetTopRecord_Api = `${environment.apiEndPoint}admin/Report/GetTopRecord`;
  public static Report_GetTopCustomerRecord_Api = `${environment.apiEndPoint}admin/Report/GetTopCustomerRecord`;
  public static Report_GetFinancialRecord_Api = `${environment.apiEndPoint}admin/Report/GetFinancialRecord`;

  //#endregion

    //#region <<AppSetting>>
    public static AppSettingList_Api = `${environment.apiEndPoint}admin/AppSetting/get`;
    public static AppSettingDetail_Api = `${environment.apiEndPoint}admin/AppSetting/get`;
    public static AppSettingAddUpdate_Api = `${environment.apiEndPoint}admin/AppSetting/Save`;
    public static AppSettingChangeActiveStatus_Api = `${environment.apiEndPoint}admin/AppSetting/ChangeActiveStatus/`;
    public static AppSettingDelete_Api = `${environment.apiEndPoint}admin/AppSetting/delete/`;
    public static AppSettingGetDetail_Api = `${environment.apiEndPoint}Public/Settings/Get`;



    //#endregion
}

export class Routing_Url {

  //#region <<Module URL>>
  public static MasterModule = 'master';
  //#endregion

  //#region <<Login URL>>
  public static LoginUrl = 'login';
  //#endregion

}

export class Message {
  //#region <<Alert Message >>
  static SaveSuccess = 'Record successfully saved...!';
  static SaveFail = 'Record failed to save...!';
  static UpdateSuccess = 'Record successfully updated...!';
  static UpdateFail = 'Record failed to update...!';
  static DeleteSuccess = 'Record successfully deleted...!';
  static DeleteFail = 'Record failed to Delete...!';
  static ConfirmUpdate = 'Are you Sure update this record?';
  static DeleteConfirmation = 'Are you want to delete record ?';
  static VerifyInput = 'Please Verify input data?';
  static AllowAutoUpdate = 'Do you want to auto update #Text?';
  static ConfirmCancel = 'Are you Sure Cancel this Order?';

  //#endregion
}

export class DropDown_key {

  static ddlState = "ddlState";
  static ddlDistrict = "ddlDistrict";
  static ddlLookupTypeMasters = "ddlLookupTypeMaster";
  static ddlCaptionTag = "ddlCaptionTag";
  static ddlCategory = "ddlCategory";
  static ddlProductSize = "ddlProductSize"
  static ddlLookup = "ddllookup"
  static ddlSublookup = "ddlSublookup";
  static ddlSubLookupGroup = "ddlSubLookupGroup";
  static ddlContentType = "ddlContentType";
  static ddlGeneralEntryCategory = "ddlGeneralEntryCategory";
  static ddlProductViewSection = "ddlProductViewSection"
  static ddlAddressType = "ddlAddressType"
  static ddlProductDiscount = "ddlProductDiscount";
  static ddlProductOccasion = "ddlProductOccasion";
  static ddlProductFabric = "ddlProductFabric";
  static ddlProductLength = "ddlProductLength";
  static ddlProductColor = "ddlProductColor";
  static ddlProductPattern = "ddlProductPattern";
  static ddlProductBrand = "ddlProductBrand";
  static ddlWarehouse = "ddlWarehouse";
  static ddlRecordType = "ddlRecordType";
}

export class EditorConfig {
  static Config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '25vh',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
}

export enum OrderStatusEnum {
  Init = "eyCvsTeZgGNU6GsQwPbiOw",
  New = "wydhZaqRHY0pD3SH0P0gXA",
  Confirmed = "-g5L1cvimURxdmSkDzl7bA",
  Failed = "MFxLbqWblnm06s4F5-jiKA",
  Cancelled = "eaZ-SiGWucqTt4KEvYIEtg",
  InProcess = "C1MrmbEnri9rOZ33ZVlhdQ",
  ShippingAssigned = "GLEG1vbpq0nUPZEYXpUhmA",
  ReadyToShip = "ZbsykbzgutUW_nkrF36Wbg",
  Dispatched = "sOggjd6lsbSff0i6OLujxg",
  Delivered = "HRrxSTFK-AfGJMkDcYWuPQ",
  Return = "Uld41RGq2HhVN6P1HaP3pw",
  Undelivered = "6pC2mqeMyAIJ66JqN6uChg",
  ReturnInit  = "Kkz8GEm5Vd1gufe13fJ0yQ",
  RTOComplete = "1J-K0C759E5dkBIeoAiYqQ",

}
export enum OrderStatusEnum_Name {
  Init = "Init",
  New = "New",
  Confirmed = "Confirmed",
  Failed = "Failed",
  Cancelled = "Cancelled",
  InProcess = "In-Process",
  ShippingAssigned = "Shipping Assigned",
  ReadyToShip = "Ready to Ship",
  Dispatched = "Dispatched",
  Delivered = "Delivered",
  Return = "Return",
  Undelivered="Undelivered",
  ReturnInit = "Return Init",
  RTOComplete = "RTO Complete",
}
