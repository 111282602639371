<!-- Page Header Start-->
<div class="page-header">
  <div class="page-header-title">
    Category List
  </div>
  <div class="page-header-action">
    <button routerLink="" class="page-header-action-items" data-title="Add Category"> <i
        class="fa-regular fa-plus"></i></button>
    <div class="page-header-action-search">
      <button type="button" (click)="onSearch()" class="btn-filter-search"><i class="fa-light fa-magnifying-glass"></i></button>
      <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
        class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search"/>
    </div>
  </div>
</div>
<!-- Page Header End-->

<!-- Main Page Content Start -->


<div class="table-responsive">
  <table class="table table-striped dataTable table-header w-100">
    <thead>
      <tr role="row">
        <th style="width: 20px;">S. no
        </th>
        <th style="width: 200px;">Category image</th>
        <th>Category name</th>
        <th style="width: 100px;">Visibility</th>
        <th style="width: 100px;">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr role="row">
        <td>01</td>
        <td>
          <div class="table-list-img d-flex align-items-center justify-content-center">
            <img src="{{appSetting?.NoImgVertical}}" ViewImage>
          </div>
        </td>
        <td>Product Category</td>
        <td>
          <label class="switch s-icons s-outline s-outline-success m-0">
            <input type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td class="table-action-btn">
          <a href="javascript:void()" class="table-action-btn-view" data-bs-toggle="tooltip" data-placement="bottom"
            title="View"><i class="fa-light fa-eye"></i></a>
          <a href="javascript:void()" class="table-action-btn-edit" data-bs-toggle="tooltip" data-placement="bottom"
            title="Edit"><i class="fa-light fa-pen"></i></a>
          <a href="javascript:void()" class="table-action-btn-delete" data-bs-toggle="tooltip" data-placement="bottom"
            title="Remove"><i class="fa-light fa-trash-can"></i></a>
        </td>
      </tr>
      <tr role="row">
        <td>02</td>
        <td>
          <div class="table-list-img d-flex align-items-center justify-content-center">
            <img src="{{appSetting?.NoImgVertical}}" ViewImage>
          </div>
        </td>
        <td>Caption tags</td>
        <td>
          <label class="switch s-icons s-outline s-outline-success m-0">
            <input type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td class="table-action-btn">
          <a href="javascript:void()" class="table-action-btn-view" data-bs-toggle="tooltip" data-placement="bottom"
            title="View"><i class="fa-light fa-eye"></i></a>
          <a href="javascript:void()" class="table-action-btn-edit" data-bs-toggle="tooltip" data-placement="bottom"
            title="Edit"><i class="fa-light fa-pen"></i></a>
          <a href="javascript:void()" class="table-action-btn-delete" data-bs-toggle="tooltip" data-placement="bottom"
            title="Remove"><i class="fa-light fa-trash-can"></i></a>
        </td>
      </tr>
      <tr role="row">
        <td>03</td>
        <td>
          <div class="table-list-img d-flex align-items-center justify-content-center">
            <img src="{{appSetting?.NoImgVertical}}" ViewImage>
          </div>
        </td>
        <td>Gender</td>
        <td>
          <label class="switch s-icons s-outline s-outline-success m-0">
            <input type="checkbox" checked>
            <span class="slider round"></span>
          </label>
        </td>
        <td class="table-action-btn">
          <a href="javascript:void()" class="table-action-btn-view" data-bs-toggle="tooltip" data-placement="bottom"
            title="View"><i class="fa-light fa-eye"></i></a>
          <a href="javascript:void()" class="table-action-btn-edit" data-bs-toggle="tooltip" data-placement="bottom"
            title="Edit"><i class="fa-light fa-pen"></i></a>
          <a href="javascript:void()" class="table-action-btn-delete" data-bs-toggle="tooltip" data-placement="bottom"
            title="Remove"><i class="fa-light fa-trash-can"></i></a>
        </td>
      </tr>

  </table>
</div>


<ul class="pagination"  *ngIf="indexModel.PageSize<totalRecords">
  <mat-paginator #paginator [length]="totalRecords" [pageSize]="indexModel.PageSize" (page)="onPaginateChange($event)" [pageIndex]="(indexModel.Page-1)"
    showFirstLastButtons *ngIf="totalRecords!=0">
  </mat-paginator>
</ul>

<!-- Main Page Content End -->
