import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GeneralEntryCategoryViewModel, GeneralEntryService, GeneralEntryViewModel } from 'src/app/Shared/Services/Master/general-entry.service';

@Component({
  selector: 'app-general-entry-master-detail',
  templateUrl: './general-entry-master-detail.component.html',
  styleUrls: ['./general-entry-master-detail.component.scss']
})
export class GeneralEntryMasterDetailComponent implements OnInit {
  id: string = "";

  @Input() set Id(value: string) {
    this.id = value;
    this.onGetDetail();
  }
  @Input('typeData') set setTypeData(value: GeneralEntryCategoryViewModel) {
    this.selectedCategory = value;
    this.model.CategoryId = this.selectedCategory.Id;
    this.pageName = this.selectedCategory.Name;

  }

  @Input() refreshData: boolean = false;
  pageName = 'General Entry'
  selectedCategory = {} as GeneralEntryCategoryViewModel;
  model = {} as GeneralEntryViewModel;
  constructor(private readonly _generalEntryService: GeneralEntryService, private readonly toast: ToastrService) { }

  ngOnInit(): void {
  }


  onGetDetail() {
    this._generalEntryService.GetGeneralEntry(this.id).subscribe(response => {
      if (response.IsSuccess) {
        this.model = response.Data as GeneralEntryViewModel;
      } else {
        this.toast.error(response.Message?.toString(), 'Error');
      }
    },
      error => {
      });
  }

  getFileType(fileName: string) {

    const ext = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['doc', 'docx', 'ppt', 'pptx', 'pdf', 'txt', 'xlx', 'xlsx'].some(x => x.toLowerCase() === ext)) {
      return 'doc';
    } else if (['jpeg', 'gif', 'png', 'jpg', 'svg', 'webp'].some(x => x.toLowerCase() === ext)) {
      return 'image';
    }
    else if (['mp4', 'mkv', 'avi',].some(x => x.toLowerCase() === ext)) {
      return 'video';
    } else {
      return ext;
    }

  }
}
