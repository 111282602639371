import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseAPIService } from '../../Helper/base-api.service';
import { ApiResponse } from '../../Helper/common-model';
import { SecurityService } from '../../Helper/security.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  //appSetting: SettingViewModel | undefined;
  private _appSetting = new BehaviorSubject<SettingViewModel | null>(null);
  get appSetting(): SettingViewModel {
    return this._appSetting.value as SettingViewModel;
  }

  constructor(private readonly _baseService: BaseAPIService, private readonly _securityService: SecurityService) {
    this.loadSettings()
  }

  loadSettings(): Observable<any> {
    if (this._securityService.getStorage('setting')) {
      this._appSetting.next(JSON.parse(this._securityService.getStorage('setting') as string) as SettingViewModel);
      return of(this._appSetting);
    }
    let url = `${this._baseService.API_Url.AppSettingGetDetail_Api}`;
    return this._baseService.get(url).pipe(debounceTime(300),
      tap((data: ApiResponse<SettingViewModel>) => {
        this._appSetting.next(data.Data as SettingViewModel);
        this._securityService.setStorage('setting', JSON.stringify(this._appSetting.value))
      })
    );
  }

  clearSettings() {
    this._securityService.removeStorage('setting')
    this._appSetting.next(null);
  }

}
export interface SettingViewModel {
  Id: string;
  Name: string;
  Title: string;
  Description: string;
  MobileNumber: string;
  WhatsAppNumber: string;
  Email: string;
  Address: string;
  AddressMapUrl: string;
  FacebookPageUrl: string;
  InstagramPageUrl: string;
  YoutubePageUrl: string;
  PinterestPageUrl: string;
  GoogleClientId: string;
  GoogleClientSecretId: string;
  MetaPixelKey: string;
  MetaPixelSecretKey: string;
  Logo: string;
  Favicon: string;
  NoImgVertical: string;
  NoImgHorizontal: string;
}


