import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseAPIService } from '../../Helper/base-api.service';
import { ApiResponse, Dictionary } from '../../Helper/common-model';
import { UserOrderModel } from './order.service';
import { RecordTypeEnum } from '../../Enum/fixed-value';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashboardCount!: DashboardViewModel;
  constructor(private readonly _baseService: BaseAPIService) { }

  GetCountDetail(data: any): Observable<ApiResponse<DashboardViewModel>> {
    let url = `${this._baseService.API_Url.Dashboard_GetCount_Api}`;
    let param = new Dictionary<any>();
    param.Add("fromDate", data?.fromDate.toLocaleDateString('en-US') ?? new Date(new Date().getDate() - 7).toLocaleDateString('en-US'));
    param.Add("toDate", data?.toDate.toLocaleDateString('en-US') ?? new Date().toLocaleDateString('en-US'));
    param.Add("type", data?.type ?? RecordTypeEnum.All);
    return this._baseService.get(url, param).pipe(take(1));
  }
}
export interface DashboardViewModel {
  NewOrder?: number;
  CompletedOrder?: number;
  TotalCustomer?: number;
  TotalSales?: number;
  TotalOrder?: number;
  NetSales?: number;
}
