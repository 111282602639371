import { Component, ElementRef, Inject, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './Shared/Helper/auth.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SettingService } from './Shared/Services/Common/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isAuth: boolean = false;
  constructor(public _authService: AuthService, private renderer: Renderer2, public readonly _setting: SettingService,
    private readonly _route: Router, @Inject(DOCUMENT) private document: Document) {
    this._authService.IsAuthenticate();
    this._authService.IsAuthentication.subscribe(x => {
      this.isAuth = x;
    });
    this.loadTheme(environment.ThemeName);

    this._setting.loadSettings().subscribe(r => {

      this.setIndexPage();
    });
  }

  loadTheme(theme: string): void {
    const themeLinkId = 'app-theme';
    let themeLink = document.getElementById(themeLinkId) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `assets/css/clients/${theme}.css`;
    } else {
      themeLink = document.createElement('link');
      themeLink.id = themeLinkId;
      themeLink.rel = 'stylesheet';
      themeLink.href = `assets/css/clients/${theme}.css`;
      document.head.appendChild(themeLink);
    }

    this.renderer.addClass(this.document.body, `${theme}`);

  }

  setIndexPage() {
    const title = this.renderer.createElement('title');
    title.innerHTML = this._setting?.appSetting?.Title ?? ''
    this.renderer.appendChild(document.head, title);

    const description = this.renderer.createElement('meta');
    description.property = "description";
    description.content = this._setting?.appSetting?.Description ?? '';
    this.renderer.appendChild(document.head, description);

    const ogDescription = this.renderer.createElement('meta');
    description.property = "og:description";
    description.content = this._setting?.appSetting?.Description ?? '';
    this.renderer.appendChild(document.head, ogDescription);

    const ogTitle = this.renderer.createElement('meta');
    ogTitle.property = "og:title";
    ogTitle.content = this._setting?.appSetting?.Name ?? '';
    this.renderer.appendChild(document.head, ogTitle);


    const ogSiteName = this.renderer.createElement('meta');
    ogSiteName.property = "og:site_name";
    ogSiteName.content = this._setting?.appSetting?.Name ?? '';
    this.renderer.appendChild(document.head, ogSiteName);



    const favicon = this.renderer.createElement('link');
    favicon.rel = "icon";
    favicon.href = this._setting?.appSetting?.Favicon ?? '';
    favicon.type = "image/x-icon";
    this.renderer.appendChild(document.head, favicon);

    const shortcut = this.renderer.createElement('link');
    shortcut.rel = "shortcut icon";
    shortcut.href = this._setting?.appSetting?.Favicon ?? '';
    shortcut.type = "image/x-icon";
    this.renderer.appendChild(document.head, shortcut);

    const appleTouch = this.renderer.createElement('link');
    appleTouch.rel = "apple-touch-icon";
    appleTouch.href = this._setting?.appSetting?.Favicon ?? '';
    appleTouch.sizes = "180x180";
    this.renderer.appendChild(document.head, appleTouch);




  }

}
