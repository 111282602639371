import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { SettingService } from '../Services/Common/setting.service';

@Directive({
  selector: 'img[ViewImage]'
})
export class ViewImageDirective {
  @Input() defaultSrc: string = '';
  @Input() isHorizontal: boolean = false;
  constructor(private renderer: Renderer2, private el: ElementRef, public readonly _setting: SettingService) {

    if (this.defaultSrc?.length == 0 && this.isHorizontal) {
      this.defaultSrc = this._setting?.appSetting?.NoImgHorizontal;
    } else {
      this.defaultSrc = this._setting?.appSetting?.NoImgVertical;
    }

  }

  ngOnChanges() {
    if (this.isHorizontal) {
      this.defaultSrc = this._setting?.appSetting?.NoImgHorizontal;
    } else {
      this.defaultSrc = this._setting?.appSetting?.NoImgVertical;
    }
    this.updateImage();
  }

  @HostListener('error', ['$event.target'])
  onError(img: HTMLImageElement) {

    img.src = this.defaultSrc; // Set fallback URL if image loading fails
  }

  private updateImage() {
    const img = this.el.nativeElement;
    let src = img.src ?? '';
    if (img && img.src) {
      if (img.src.includes('drive.google.com')) {
        const fileId = this.extractIdFromGoogleDriveUrl(img.src);
        if (fileId) {
          src = `https://lh3.googleusercontent.com/d/${fileId}`;
        }
      }
      if (img.src.includes('dropbox.com')) {
        const fileId = this.extractIdFromDropBoxUrl(img.src);
        if (fileId) {
          src = `https://dl.dropboxusercontent.com/s/${fileId}`;
        }
      } else if (src.length == 0) {
        src = this.defaultSrc;
      }
    }
    this.renderer.setAttribute(img, 'src', src);
  }

  private extractIdFromGoogleDriveUrl(url: string): string | null {
    const match = url.match(/\/d\/([^\/]+)/); // Regular expression pattern to extract ID from URL

    if (match && match.length > 1) {
      return match[1]; // Extracted ID value
    } else {
      return this.defaultSrc;
    }
  }

  private extractIdFromDropBoxUrl(url: string): string | null {
    const regexPattern: RegExp = /\/s\/([^/?]+)\/([^/?]+)/;
    const match: RegExpMatchArray | null = url.match(regexPattern);
    if (match && match.length > 1) {
      return `${match[1]}/${match[2]}`; // Extracted ID value
    } else {
      return this.defaultSrc;
    }
  }
}
