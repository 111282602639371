<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    {{model.Id !=null ? 'Update Product':'Add New Product'}}
  </div>
  <div class="page-header-action">
    <button (click)="backToPrevious()" class="page-header-action-items" data-title="Back to list"> <i
        class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->
<div class="content-box">
  <form [formGroup]="formgrp" (ngSubmit)="onSubmit()">
    <div class="row">
      <!-- Done -->
      <div class="col-md-2 col-xl-2 mb-3">
        <label>SKU ID<span class="text-danger">*</span></label>
        <input type="text" class="form-control" formControlName="UniqueID" [(ngModel)]="model.UniqueId"
          [ngClass]="{ 'is-invalid': f.UniqueID.errors && f.UniqueID.touched}" placeholder="SKU ID">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('UniqueID')?.hasError('required') && formgrp.get('UniqueID')?.touched)">
          Please enter <strong>SKU ID</strong>...!
        </div>
        <div class="invalid-feedback"
          *ngIf="(!formgrp.get('UniqueID')?.hasError('required') && formgrp.get('UniqueID')?.touched) &&formgrp.get('UniqueID')?.hasError('duplicateSKU')">
          SKU number already taken ..!
        </div>

      </div>
      <div class="col-md-7 col-lg-7 col-xl-8 mb-3">
        <label>Name<span class="text-danger">*</span></label>
        <input type="text" class="form-control" [(ngModel)]="model.Name" formControlName="Name"
          [ngClass]="{ 'is-invalid': f.Name.errors && f.Name.touched}" placeholder="Name">
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Name')?.hasError('required') && formgrp.get('Name')?.touched)">
          Please enter <strong>Name</strong>...!
        </div>
      </div>

      <div class="col-md-3 col-lg-3 col-xl-2 mb-3">
        <label>Type<span class="text-danger">*</span></label>
        <ng-select [items]="dropDown.ddlRecordType" formControlName="ProductType" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select Product Type" (ngModelChange)="updatePriceField($event)"
          [ngClass]="{ 'is-invalid': f.ProductType.errors && f.ProductType.touched}" [(ngModel)]="model.Type">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ProductType')?.hasError('required') && formgrp.get('ProductType')?.touched)">
          Please select <strong>Product Type</strong>...!
        </div>
      </div>

      <!-- <div class="col-md-2 mb-3">
        <label>Discount</label>
        <ng-select [items]="dropDown.ddlProductDiscount" formControlName="Discount" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select Discount" [(ngModel)]="model.DiscountId"
          [ngClass]="{ 'is-invalid': f.Discount.errors && f.Discount.touched}">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Discount')?.hasError('required') && formgrp.get('Discount')?.touched)">
          Please select <strong>Discount</strong>...!
        </div>
      </div> -->

      <div *ngIf="model.Type"
        [ngClass]="{'col-lg-6':model.Type==productTypeEnum.All,'col-lg-12':model.Type==productTypeEnum.BtoC,'d-none':model.Type==productTypeEnum.BtoB}">
        <fieldset class="border border-radius p-2 w-100 mt-3 mb-3 pb-3 px-3">
          <div class="d-flex mb-1 mt--25 fieldset-header">
            <legend class="w-auto bg-white px-2 m-0">B2C Price</legend>
          </div>
          <div class="row">
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Price<span class="text-danger">*</span></label>
              <input type="text" [(ngModel)]="model.Price" (blur)="checkSellingPrice(false)"
                (keypress)="_commonService.NumberOnly($event);" [minlength]="1" [maxLength]="10" formControlName="Price"
                [ngClass]="{ 'is-invalid': f.Price.errors && f.Price.touched}" class="form-control" id="input4"
                placeholder="Price">
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Price')?.hasError('required') && formgrp.get('Price')?.touched)">
                Please enter Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!formgrp.get('Price')?.hasError('required') && ((formgrp.get('Price')?.hasError('maxlength') || formgrp.get('Price')?.hasError('minlength')) && formgrp.get('Price')?.touched) || (formgrp.get('Price')?.hasError('maxlength') || formgrp.get('Price')?.hasError('minlength'))">
                Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!formgrp.get('Price')?.hasError('required') && formgrp.get('Price')?.touched) &&formgrp.get('Price')?.hasError('minValue')">
                price must be equals or greater then {{model.SellingPrice??0}}
              </div>

            </div>
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Selling Price<span class="text-danger">*</span></label>
              <input type="text" [(ngModel)]="model.SellingPrice" (blur)="checkSellingPrice(false)"
                (keypress)="_commonService.NumberOnly($event);" [minlength]="1" [maxLength]="10" [min]="model.Price"
                formControlName="SellingPrice"
                [ngClass]="{ 'is-invalid': f.SellingPrice.errors && f.SellingPrice.touched}" class="form-control"
                id="input4" placeholder="Selling Price">
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('SellingPrice')?.hasError('required') && formgrp.get('SellingPrice')?.touched)">
                Please enter Selling Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!formgrp.get('SellingPrice')?.hasError('required') && ((formgrp.get('SellingPrice')?.hasError('maxlength') || formgrp.get('SellingPrice')?.hasError('minlength')) && formgrp.get('SellingPrice')?.touched) || (formgrp.get('SellingPrice')?.hasError('maxlength') || formgrp.get('SellingPrice')?.hasError('minlength'))">
                Selling Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!formgrp.get('SellingPrice')?.hasError('required') && formgrp.get('SellingPrice')?.touched) &&formgrp.get('SellingPrice')?.hasError('minValue')">
                Selling price must be equals or less then {{model.Price??0}}
              </div>


            </div>
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Discount</label>
              <div class="static-form-control">
                {{getDiscountValue(model?.Price??0,model?.SellingPrice??0)}}%
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div *ngIf="model.Type"
        [ngClass]="{'col-lg-6':model.Type==productTypeEnum.All,'col-lg-12':model.Type==productTypeEnum.BtoB,'d-none':model.Type==productTypeEnum.BtoC}">
        <fieldset class="border border-radius p-2 w-100 mt-3 mb-3 pb-3 px-3">
          <div class="d-flex mb-1 mt--25 fieldset-header">
            <legend class="w-auto bg-white px-2 m-0">B2B Price</legend>
          </div>
          <div class="row">
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Price<span class="text-danger">*</span></label>
              <input type="text" [(ngModel)]="model.BtoBPrice" (blur)="checkSellingPrice(true)"
                (keypress)="_commonService.NumberOnly($event);" [minlength]="1" [maxLength]="10"
                formControlName="BtoBPrice" [ngClass]="{ 'is-invalid': f.BtoBPrice.errors && f.BtoBPrice.touched}"
                class="form-control" id="input4" placeholder="Price">
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('BtoBPrice')?.hasError('required') && formgrp.get('BtoBPrice')?.touched)">
                Please enter Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!formgrp.get('BtoBPrice')?.hasError('required') && ((formgrp.get('BtoBPrice')?.hasError('maxlength') || formgrp.get('BtoBPrice')?.hasError('minlength')) && formgrp.get('BtoBPrice')?.touched) || (formgrp.get('BtoBPrice')?.hasError('maxlength') || formgrp.get('BtoBPrice')?.hasError('minlength'))">
                Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!formgrp.get('BtoBPrice')?.hasError('required') && formgrp.get('BtoBPrice')?.touched) &&formgrp.get('BtoBPrice')?.hasError('minValue')">
                price must be equals or greater then {{model.BtoBSellingPrice??0}}
              </div>

            </div>
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Selling Price<span class="text-danger">*</span></label>
              <input type="text" [(ngModel)]="model.BtoBSellingPrice" (blur)="checkSellingPrice(true)"
                (keypress)="_commonService.NumberOnly($event);" [minlength]="1" [maxLength]="10" [min]="model.Price"
                formControlName="BtoBSellingPrice"
                [ngClass]="{ 'is-invalid': f.BtoBSellingPrice.errors && f.BtoBSellingPrice.touched}"
                class="form-control" id="input4" placeholder="Selling Price">
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('BtoBSellingPrice')?.hasError('required') && formgrp.get('BtoBSellingPrice')?.touched)">
                Please enter Selling Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!formgrp.get('BtoBSellingPrice')?.hasError('required') && ((formgrp.get('BtoBSellingPrice')?.hasError('maxlength') || formgrp.get('BtoBSellingPrice')?.hasError('minlength')) && formgrp.get('BtoBSellingPrice')?.touched) || (formgrp.get('BtoBSellingPrice')?.hasError('maxlength') || formgrp.get('BtoBSellingPrice')?.hasError('minlength'))">
                Selling Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!formgrp.get('BtoBSellingPrice')?.hasError('required') && formgrp.get('BtoBSellingPrice')?.touched) &&formgrp.get('BtoBSellingPrice')?.hasError('minValue')">
                Selling price must be equals or less then {{model.Price??0}}
              </div>


            </div>
            <div class="col-sm-4 mb-2 mb-sm-0">
              <label>Discount</label>
              <div class="static-form-control">
                {{getDiscountValue(model?.BtoBPrice??0,model?.BtoBSellingPrice??0)}}%
              </div>
            </div>
          </div>
        </fieldset>
      </div>


      <div class="col-md-6 col-lg-4 mb-3" [ngClass]="{'d-none':this.dropDown?.ddlWarehouse?.length == 1}">
        <label>Warehouse<span class="text-danger">*</span></label>
        <ng-select [items]="dropDown.ddlWarehouse" formControlName="Warehouse" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select Warehouse" (ngModelChange)="getBrandDropDown($event)"
          [ngClass]="{ 'is-invalid': f.Warehouse.errors && f.Warehouse.touched}" [(ngModel)]="model.WarehouseId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Category')?.hasError('required') && formgrp.get('Category')?.touched)">
          Please select <strong>Warehouse</strong>...!
        </div>
      </div>

      <div class="col-md-6 col-lg-3 mb-3">
        <label>Brand<span class="text-danger">*</span></label>
        <ng-select [items]="dropDown.ddlProductBrand" formControlName="Brand" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select Brand" [ngClass]="{ 'is-invalid': f.Brand.errors && f.Brand.touched}"
          [(ngModel)]="model.BrandId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Category')?.hasError('required') && formgrp.get('Category')?.touched)">
          Please select <strong>Brand</strong>...!
        </div>
      </div>

      <div class="col-md-6 col-lg-3 mb-3">
        <label>Category<span class="text-danger">*</span></label>
        <ng-select [items]="dropDown.ddlCategory" formControlName="Category" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select category" id="Category" (ngModelChange)="getSubLookUpDropDown($event)"
          [ngClass]="{ 'is-invalid': f.Category.errors && f.Category.touched}" [(ngModel)]="model.CategoryId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Category')?.hasError('required') && formgrp.get('Category')?.touched)">
          Please select <strong>Category</strong>...!
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <label>Sub Category</label>
        <ng-select [items]="dropDown.ddlSublookup" formControlName="SubCategory" [searchable]="true" bindLabel="Text"
          bindValue="Value" placeholder="Select Sub-category"
          [ngClass]="{ 'is-invalid': f.SubCategory.errors && f.SubCategory.touched}" [(ngModel)]="model.SubCategoryId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('SubCategory')?.hasError('required') && formgrp.get('SubCategory')?.touched)">
          Please select <strong>Sub Category</strong>...!
        </div>
      </div>
      <div class="col-md-6 col-lg-3 mb-3">
        <label>Home Page Section</label>
        <ng-select [items]="dropDown.ddlProductViewSection" formControlName="ViewSection" [searchable]="true"
          bindLabel="Text" bindValue="Value" placeholder="--Select--"
          [ngClass]="{ 'is-invalid': f.ViewSection.errors && f.ViewSection.touched}" [(ngModel)]="model.ViewSectionId">
        </ng-select>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ViewSection')?.hasError('required') && formgrp.get('ViewSection')?.touched)">
          Please select <strong>Home Page Section</strong>...!
        </div>
      </div>
      <div class="col-lg-12">
        <fieldset class="border border-radius p-2 w-100 mt-3 mb-3 px-3">
          <div class="d-flex mb-1 mt--25 fieldset-header">
            <legend class="w-auto bg-white px-2 m-0">Additional Features</legend>
          </div>
          <div class="row">
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Occasion</label>
              <ng-select [items]="dropDown.ddlProductOccasion" formControlName="Occasion" [searchable]="true"
                bindLabel="Text" bindValue="Value" placeholder="Select Occasion" [(ngModel)]="model.OccasionId"
                [ngClass]="{ 'is-invalid': f.Occasion.errors && f.Occasion.touched}">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Occasion')?.hasError('required') && formgrp.get('Occasion')?.touched)">
                Please select <strong>Occasion</strong>...!
              </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Fabric</label>
              <ng-select [items]="dropDown.ddlProductFabric" formControlName="Fabric" [searchable]="true"
                bindLabel="Text" bindValue="Value" placeholder="Select Fabric" [(ngModel)]="model.FabricId"
                [ngClass]="{ 'is-invalid': f.Fabric.errors && f.Fabric.touched}">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Fabric')?.hasError('required') && formgrp.get('Fabric')?.touched)">
                Please select <strong>Fabric</strong>...!
              </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Length</label>
              <ng-select [items]="dropDown.ddlProductLength" formControlName="Length" [searchable]="true"
                bindLabel="Text" bindValue="Value" placeholder="Select Length" [(ngModel)]="model.LengthId"
                [ngClass]="{ 'is-invalid': f.Length!.errors && f.Length!.touched}">
              </ng-select>
             <div class="invalid-feedback"
                *ngIf="(formgrp.get('Length')?.hasError('required') && formgrp.get('Length')?.touched)">
                Please select <strong>Length</strong>...!
              </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Color</label>
              <ng-select [items]="dropDown.ddlProductColor" formControlName="Color" [searchable]="true" bindLabel="Text"
                bindValue="Value" placeholder="Select Color" [(ngModel)]="model.ColorId"
                [ngClass]="{ 'is-invalid': f.Color.errors && f.Color.touched}">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Color')?.hasError('required') && formgrp.get('Color')?.touched)">
                Please select <strong>Color</strong>...!
              </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Pattern</label>
              <ng-select [items]="dropDown.ddlProductPattern" formControlName="Pattern" [searchable]="true"
                bindLabel="Text" bindValue="Value" placeholder="Select Pattern" [(ngModel)]="model.PatternId"
                [ngClass]="{ 'is-invalid': f.Pattern.errors && f.Pattern.touched}">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Pattern')?.hasError('required') && formgrp.get('Pattern')?.touched)">
                Please select <strong>Pattern</strong>...!
              </div>
            </div>
            <div class="col-xxl-2 col-xl-3 col-md-3 col-sm-6 mb-3">
              <label>Tag/Label</label>
              <ng-select [items]="dropDown.ddlCaptionTag" formControlName="Caption" [searchable]="true" bindLabel="Text"
                bindValue="Value" placeholder="Select Tag"
                [ngClass]="{ 'is-invalid': f.Caption.errors && f.Caption.touched}" [(ngModel)]="model.CaptionTagId">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(formgrp.get('Caption')?.hasError('required') && formgrp.get('Caption')?.touched)">
                Please select <strong>Caption</strong>...!
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="col-md-6 col-lg-5 mb-0">
          <label>Main image <span class="text-danger">*</span>  <span class="info-tooltip"> <i class="fa-regular fa-circle-info"></i> <div class="info-tooltip-hover"> Preferred size : 640*920 </div></span></label>
        <input type="hidden" name="ImagePath" [(ngModel)]="model.ImagePath" formControlName="ImagePath">
        <app-file-selector [MaxFileLength]="1" (FilesChanged)="onDocumentAttach($event)"
          [CurrentFileLength]="model.ImagePath ? 1: 0" [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'"
          [ngClass]="{ 'is-invalid': f.ImagePath.errors && f.ImagePath.touched}">
        </app-file-selector>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('ImagePath')?.hasError('required') && formgrp.get('ImagePath')?.touched)">
          Please Product main image...!
        </div>
        <ng-container *ngIf="model.ImagePath && !isFileAttached  && model.Id!=null">
          <span [ngSwitch]="getFileType(model.ImagePath)">
            <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative">

              <div class="small-media-items-img">
                <img [src]="model.ImagePath" ViewImage  />
              </div>
              <div class="small-media-items-action">
                <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                    class="fa-light fa-trash-can"></i></a>
                <a [href]="model.ImagePath" target="_blank">
                  <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                </a>
              </div>
            </a>
            <a *ngSwitchCase="'doc'" href="javascript:void()"
              class="small-media-items small-media-items-pdf text-danger position-relative mb-3">
              <div class="small-media-items-img"><i class="bi bi-file-earmark-pdf"></i></div>
              <div class="small-media-items-action">
                <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                    class="fa-light fa-trash-can"></i></a>
                <a href="javascript:void()" [href]="model.ImagePath" title="View"><i class="fa-light fa-eye"></i></a>
              </div>
            </a>
            <a *ngSwitchCase="'video'" href="javascript:void()"
              class="small-media-items small-media-items-video position-relative mb-3">
              <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
              <div class="small-media-items-action">
                <a href="javascript:void()" (click)="RemoveDocument(model.ImagePath)" title="Delete"><i
                    class="fa-light fa-trash-can"></i></a>
                <a href="javascript:void()" [href]="model.ImagePath" title="View"><i class="fa-light fa-eye"></i></a>
              </div>
            </a>
            <p *ngSwitchDefault>
            </p>
          </span>
        </ng-container>
      </div>
      <div class="col-md-6 col-lg-7 mb-0">
        <label> Slider images  <span class="info-tooltip"> <i class="fa-regular fa-circle-info"></i> <div class="info-tooltip-hover"> Preferred size : 640*920 </div></span></label>
        <div class="dropzone dropzone-light upload-btn-wrapper w-100">
     <input type="hidden" name="productFile" [(ngModel)]="productFile" formControlName="productFile">
          <app-file-selector [MaxFileLength]="10" (FilesChanged)="onProductFileAttach($event)"
            [FileFilter]="'.jpeg,.gif,.png,.jpg,.webp'"
            [CurrentFileLength]="TotalFiles"
            [ngClass]="{ 'is-invalid': f.productFile!.errors && f.productFile!.touched}">
          </app-file-selector>
          <div class="invalid-feedback"
            *ngIf="(formgrp.get('productFile')?.hasError('required') && formgrp.get('productFile')?.touched)">
            Please Add image...!
          </div>
        </div>
        <div class="d-flex flex-wrap align-items-center upload-content-list" *ngIf="ProductFiles">
          <ng-container *ngFor="let itm of ProductFiles">
            <span [ngSwitch]="getFileType(itm.FilePath)">
              <a *ngSwitchCase="'image'" href="javascript:void()" class="small-media-items position-relative">
                <div class="small-media-items-img"> <img [src]="itm.FilePath" ViewImage
                     /> </div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteProductFile(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.FilePath" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>
              <a *ngSwitchCase="'doc'" href="javascript:void()"
                class="small-media-items small-media-items-pdf text-danger position-relative mb-3">
                <div class="small-media-items-img"><i class="bi bi-file-earmark-pdf"></i></div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteProductFile(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.FilePath" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>
              <a *ngSwitchCase="'video'" href="javascript:void()"
                class="small-media-items small-media-items-video position-relative mb-3">
                <div class="small-media-items-img"> <i class="bi bi-camera-video"></i> </div>
                <div class="small-media-items-action">
                  <a href="javascript:void()" (click)="deleteProductFile(itm.Id)" title="Delete"><i
                      class="fa-light fa-trash-can"></i></a>
                  <a [href]="itm.FilePath" target="_blank">
                    <i data-bs-toggle="tooltip" data-placement="bottom" title="View" class="fa-light fa-eye"></i>
                  </a>
                </div>
              </a>
            </span>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label>Description</label>
        <angular-editor [(ngModel)]="model.Desc" formControlName="Description" [config]="editorConfig"
          placeholder="Description" [ngClass]="{ 'is-invalid': f.Description.errors && f.Description.touched}"
          id="Description"></angular-editor>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Description')?.hasError('required') && formgrp.get('Description')?.touched)">
          Please enter Description
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label>Additional Information</label>
        <angular-editor [(ngModel)]="model.Summary" formControlName="Summary" [config]="editorConfig"
          [ngClass]="{ 'is-invalid': f.Summary.errors && f.Summary.touched}" id="Summary" rows="5"
          placeholder="Additional Information">
        </angular-editor>
        <div class="invalid-feedback"
          *ngIf="(formgrp.get('Summary')?.hasError('required') && formgrp.get('Summary')?.touched)">
          Please enter Summary...!
        </div>
      </div>
      <div class="col-lg-12">
        <fieldset class="border border-radius p-2 w-100  mt-4 mb-3">
          <div class="d-flex align-items-center justify-content-between mb-2 mt--25 pr-0 pr-xl-3 fieldset-header">
            <legend class="w-auto text-black bg-white px-3 m-0">Stock<span class="text-danger">*</span>
            </legend>
            <div>
              <button *ngIf=" model.Type==productTypeEnum.All ||model.Type==productTypeEnum.BtoC" type="button"
                (click)="onAddStock(false)" class="fieldset-header-btn" data-bs-toggle="modal"
                data-bs-target="#stockModelPopup">
                <i class="fa-regular fa-plus"></i> Add B2C Stock
              </button>
              <button *ngIf="model.Type==productTypeEnum.All || model.Type==productTypeEnum.BtoB" type="button"
                (click)="onAddStock(true)" class="fieldset-header-btn ms-2" data-bs-toggle="modal"
                data-bs-target="#stockModelPopup">
                <i class="fa-regular fa-plus"></i> Add B2B Stock
              </button>
            </div>
          </div>
          <div class="col-md-12 m-0">
            <div class="table-responsive-md">
              <table class="custom-mat-table table-header-light">
                <thead>
                  <tr>
                    <th width="50px">#</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Unit Price</th>
                    <th>Selling Price</th>
                    <th>Quantity</th>
                    <th width="80px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of this.model.Stocks;let index=index">
                    <td scope="row">{{index+1}}</td>
                    <td>{{item.IsBtoB?'B2B':'B2C'}}</td>
                    <td> <strong> {{onGetProductSizeLabel(item.SizeId)}} </strong></td>
                    <td>{{item.UnitPrice}}</td>
                    <td>{{item.SellingPrice}}</td>
                    <td>{{item.Quantity}}</td>
                    <td class="table-action-btn">
                      <a href="javascript:void(0)" class="table-action-btn-edit" (click)="onEditStock(item,index)"
                        data-title="Edit" data-bs-toggle="modal" data-bs-target="#stockModelPopup"><i
                          class="fa-light fa-pen"></i>
                      </a>
                      <a class="table-action-btn-delete" data-title="Delete" (click)="onRemoveStock(index)">
                        <i class="fa-light fa-trash-can"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="accordion mt-1" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="Seo-Work">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#SeoFrom">
              SEO Related Form
            </button>
          </h2>
          <div id="SeoFrom" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label>Keyword</label>
                  <textarea [(ngModel)]="model.Keyword" formControlName="Keyword" placeholder="Keyword"
                    class="form-control" [ngClass]="{ 'is-invalid': f.Keyword.errors && f.Keyword.touched}"
                    id="Keyword"></textarea>
                  <div class="invalid-feedback"
                    *ngIf="(formgrp.get('Keyword')?.hasError('required') && formgrp.get('Keyword')?.touched)">
                    Please enter Keyword
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 mb-3">
                  <label>Meta Title</label>
                  <textarea [(ngModel)]="model.MetaTitle" formControlName="MetaTitle" placeholder="Meta Title"
                    class="form-control" [ngClass]="{ 'is-invalid': f.MetaTitle.errors && f.MetaTitle.touched}"
                    id="MetaTitle"></textarea>
                  <div class="invalid-feedback"
                    *ngIf="(formgrp.get('MetaTitle')?.hasError('required') && formgrp.get('MetaTitle')?.touched)">
                    Please enter Meta Title
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 mb-3">
                  <label>Meta Description</label>
                  <textarea [(ngModel)]="model.MetaDesc" formControlName="MetaDesc" placeholder="Meta Description"
                    class="form-control" [ngClass]="{ 'is-invalid': f.MetaDesc.errors && f.MetaDesc.touched}"
                    id="MetaDesc"></textarea>
                  <div class="invalid-feedback"
                    *ngIf="(formgrp.get('MetaDesc')?.hasError('required') && formgrp.get('MetaDesc')?.touched)">
                    Please enter Meta Description
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-footer-btn-grp widget-content widget-content pt-4 text-center">
      <button type="submit" class="btn btn-default me-3"> <i class="fas fa-check me-1"></i> {{model.Id !=null ?
        'Save Product' : 'Add Product'}}</button>
      <button type="button" (click)="onCloseClick()" class="btn btn-default btn-border"> <i class="fas fa-times me-1"></i>
        Cancel</button>
    </div>
  </form>
</div>
<div class="custom-modal model-stock-create modal fade" data-backdrop="static" data-bs-keyboard="true"
  id="stockModelPopup" #stockModelPopup>
  <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header ">
        <h5 class="modal-title m-0">Stock</h5>
        <button type="button" #stockModelPopupClose class="modal-close" (click)="onCancelEdit()" data-bs-dismiss="modal"
          aria-label="Close">
          <i class="fa-light fa-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="stockFormGroup" (ngSubmit)="onSaveStock()">
          <div class="row">
            <div class="col-lg-3 mb-3 mb-lg-0">
              <label>Select Size<span class="text-danger">*</span></label>
              <ng-select [items]="ddlAvailableProductSize" formControlName="SizeId" [searchable]="true" bindLabel="Text"
                bindValue="Value" placeholder="Select Size"
                [ngClass]="{ 'is-invalid': sf.SizeId.errors && sf.SizeId.touched}" [(ngModel)]="stockModel.SizeId">
              </ng-select>
              <div class="invalid-feedback"
                *ngIf="(stockFormGroup.get('SizeId')?.hasError('required') && stockFormGroup.get('SizeId')?.touched)">
                Please select <strong>Size</strong>...!
              </div>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0 unit-pro-price">
              <label class="d-flex justify-content-between">
                <div> Unit Price<span class="text-danger">*</span> </div>
                <a href="javascript:void(0)" class="text-primary font-w-200" *ngIf="this.model?.Price"
                  data-title="₹ Product Price" (click)="applyMainPrice()">
                  <i class="fa-light fa-retweet"></i>
                </a>
              </label>
              <input type="number" readOnly [(ngModel)]="stockModel.UnitPrice" (blur)="checkStockSellingPrice()"
                (keypress)="_commonService.NumberOnly($event)" [minlength]="1" [maxLength]="10"
                formControlName="UnitPrice" [ngClass]="{ 'is-invalid': sf.UnitPrice.errors && sf.UnitPrice.touched}"
                class="form-control" id="input4" placeholder="Unit Price">
              <!-- <button type="button" *ngIf="this.model?.Price" title="Add Product Price" (click)="applyMainPrice()">₹ Product Price</button> -->
              <div class="invalid-feedback"
                *ngIf="(stockFormGroup.get('UnitPrice')?.hasError('required') && stockFormGroup.get('UnitPrice')?.touched)">
                Please enter Unit Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!stockFormGroup.get('UnitPrice')?.hasError('required') && ((stockFormGroup.get('UnitPrice')?.hasError('maxlength') || stockFormGroup.get('UnitPrice')?.hasError('minlength')) && stockFormGroup.get('UnitPrice')?.touched) || (stockFormGroup.get('UnitPrice')?.hasError('maxlength') || stockFormGroup.get('UnitPrice')?.hasError('minlength'))">
                Unit Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!stockFormGroup.get('UnitPrice')?.hasError('required') && stockFormGroup.get('UnitPrice')?.touched) &&stockFormGroup.get('UnitPrice')?.hasError('minValue')">
                Selling price must be equals or less then {{stockModel.SellingPrice??0}}
              </div>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0 unit-pro-price">
              <label class="d-flex justify-content-between">
                <div> Selling Price<span class="text-danger">*</span> </div>
                <a href="javascript:void(0)" class="text-primary font-w-200" *ngIf="this.model?.Price"
                  data-title="₹ Selling Price" (click)="applySellingPrice()"><i class="fa-light fa-retweet"></i></a>
              </label>
              <input type="number" readOnly [(ngModel)]="stockModel.SellingPrice" (blur)="checkStockSellingPrice()"
                (keypress)="_commonService.NumberOnly($event)" [minlength]="1" [maxLength]="10"
                formControlName="SellingPrice"
                [ngClass]="{ 'is-invalid': sf.SellingPrice.errors && sf.SellingPrice.touched}" class="form-control"
                id="input4" placeholder="Selling Price">
              <!-- <button type="button" *ngIf="this.model?.Price" title="Add Product Price" (click)="applyMainPrice()">₹ Product Price</button> -->
              <div class="invalid-feedback"
                *ngIf="(stockFormGroup.get('SellingPrice')?.hasError('required') && stockFormGroup.get('SellingPrice')?.touched)">
                Please enter Selling Price...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!stockFormGroup.get('SellingPrice')?.hasError('required') && ((stockFormGroup.get('SellingPrice')?.hasError('maxlength') || stockFormGroup.get('SellingPrice')?.hasError('minlength')) && stockFormGroup.get('SellingPrice')?.touched) || (stockFormGroup.get('SellingPrice')?.hasError('maxlength') || stockFormGroup.get('SellingPrice')?.hasError('minlength'))">
                Selling Price length must be 1-10 digit.
              </div>
              <div class="invalid-feedback"
                *ngIf="(!stockFormGroup.get('SellingPrice')?.hasError('required') && stockFormGroup.get('SellingPrice')?.touched) &&stockFormGroup.get('SellingPrice')?.hasError('minValue')">
                Selling price must be equals or less then {{stockModel.UnitPrice??0}}
              </div>
            </div>
            <div class="col-lg-3 mb-3 mb-lg-0">
              <label>Quantity<span class="text-danger">*</span></label>
              <input type="number" [(ngModel)]="stockModel.Quantity" (keypress)="_commonService.NumberOnly($event)"
                [minlength]="1" [maxLength]="10" formControlName="Quantity"
                [ngClass]="{ 'is-invalid': sf.Quantity.errors && sf.Quantity.touched}" class="form-control" id="input4"
                placeholder="Quantity">
              <div class="invalid-feedback"
                *ngIf="(stockFormGroup.get('Quantity')?.hasError('required') && stockFormGroup.get('Quantity')?.touched)">
                Please enter Quantity...!
              </div>
              <div class="invalid-feedback"
                *ngIf="!stockFormGroup.get('Quantity')?.hasError('required') && ((stockFormGroup.get('Quantity')?.hasError('maxlength') || stockFormGroup.get('Quantity')?.hasError('minlength')) && stockFormGroup.get('Quantity')?.touched) || (stockFormGroup.get('Quantity')?.hasError('maxlength') || stockFormGroup.get('Quantity')?.hasError('minlength'))">
                Quantity length must be 1-10 digit.
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 w-100 p-0 mt-3">
            <button type="submit" class="btn btn-default"><i class="fas fa-check me-1"></i> Save</button>

            <button type="button" (click)="onCancelEdit()" class="btn btn-default btn-border" data-bs-dismiss="modal"> <i
                class="fas fa-times me-1"></i>
              Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
