import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileInfo } from 'src/app/Shared/Helper/shared/file-selector/file-selector.component';
import { CommonService } from "src/app/Shared/Services/Common/common.service";

@Component({

  selector: 'app-e-docket',
  templateUrl: './e-docket.component.html',
  styleUrls: ['./e-docket.component.scss']
})
export class EDocketComponent implements OnInit {

  @Output() onSubmit = new EventEmitter<FileInfo>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onDownloadClick = new EventEmitter<void>();
  @Input() IsShowEDocket = false;
  @Input() FileAccept = '.xlx,.xlsx,.csv';
  @ViewChild('ShowPopupBtn') ShowPopupBtn!: ElementRef;
  @ViewChild('hidePopupBtn') hidePopupBtn!: ElementRef;
  downloadFile!: SafeUrl;
  isValidFile = true;
  file: any;
  constructor(public readonly domSanitizer: DomSanitizer, private _commonService: CommonService) { }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes?.IsShowEDocket?.currentValue != changes?.IsShowEDocket?.previousValue) {
      if (this.IsShowEDocket) {
        this.ShowPopupBtn?.nativeElement?.click();
        this.file = null;
      } else {
        this.hidePopupBtn?.nativeElement?.click();
        this.IsShowEDocket = false;
      }
    }

  }
  ngOnInit(): void {
  }
  onDocumentAttach(file: FileInfo[]) {
    if (file.length > 0) {
      this.file = file[0].FileBase64;
    } else {
      this.file = null;
    }
  }

  onSubmitClick() {
    this.IsShowEDocket = false;
    this.onSubmit.emit(this.file);
    this.hidePopupBtn?.nativeElement?.click();

  }
  onCancelClick() {
    this.IsShowEDocket = false;
    this.onCancel.emit(this.file);
    this.hidePopupBtn?.nativeElement?.click();

  }
  onDownloadFile() {
    this.onDownloadClick.emit();
  }
}
