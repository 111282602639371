import { Component, OnInit, Inject, EventEmitter, Input, Output } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { DropDown_key, EditorConfig } from "src/app/Shared/Helper/constants";
import { FileInfo } from "src/app/Shared/Helper/shared/file-selector/file-selector.component";
import { CommonService } from "src/app/Shared/Services/Common/common.service";
import { LookupTypeMasterModel } from "src/app/Shared/Services/Master/lookup-type.service";
import { SubLookupMasterPostModel, SubLookupService } from "src/app/Shared/Services/Master/sub-lookup.service";


@Component({
  selector: 'app-sub-lookup-add-edit',
  templateUrl: './sub-lookup-add-edit.component.html',
  styleUrls: ['./sub-lookup-add-edit.component.scss']
})
export class SubLookupAddEditComponent implements OnInit {
  model = {} as SubLookupMasterPostModel;
  isFileAttached = false;
  formgrp = this.fb.group({
    Name: [undefined, Validators.required],
    SortedOrder: [undefined, Validators.required],
    ImagePath: [undefined],
    Info: [undefined],
    MetaTitle: [undefined],
    MetaDescription: [undefined],
    Url: [undefined],
    BtoBInfo: [undefined],
    BtoBMetaTitle: [undefined],
    BtoBMetaDescription: [undefined],
    BtoBUrl: [undefined],

  });
  get ddlkeys() { return DropDown_key };
  get f() { return this.formgrp.controls; }
  get getFileName() { return this.model.ImagePath ? this.model.ImagePath.split('/')[this.model.ImagePath.split('/').length - 1] : '' }

  @Output() OnSave = new EventEmitter<{ status: boolean, recordId: string }>();
  @Output() OnClose = new EventEmitter<boolean>();
  _typeData = {} as LookupTypeMasterModel;
  @Input('typeData') set typeData(value: LookupTypeMasterModel) {
    this._typeData = value;

  }
  ViewMode = 'detail'
  @Input('mode') set mode(value: string) {
    this.ViewMode = value
  }
  @Input('Id') set Id(value: string) {

    this.reset();
    this.model.Id = value;
    if (this.model !== null && this.model.Id?.length > 0) {
      this.getDetail();
    }
  }
  lookupId = '';
  @Input('lookupId') set _lookupId(value: string) {
    this.reset();
    this.lookupId = value;
  }
  editorConfig = EditorConfig.Config;
  constructor(private readonly _lookupService: SubLookupService,
    private readonly fb: FormBuilder, public _commonService: CommonService, private readonly toast: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.model.Id.length > 0) {
      this.getDetail();
    }
  }

  onClose(result: boolean = true) {
    this.OnClose.emit(result);
  }
  onSubmit() {
    this.formgrp.markAllAsTouched();
    if (this.formgrp.valid) {
      this.model.LookUpId = this.lookupId;
      this.model.Id = this.model.Id;
      this.model.SortedOrder = Number(this.model.SortedOrder),
        this._lookupService.AddUpdateLookupMaster(this.model).subscribe(x => {
          if (x.IsSuccess) {
            this.toast.success(x.Message as string);
            this.onClose(true);
          } else {
            this.toast.error(x.Message as string);
            this.onClose(false);
          }


        }, error => { });

    }
  }

  RemoveDocument(file: string) {
    this.model.ImagePath = '';
  }
  getDetail() {
    this._lookupService.GetLookupMaster(this.model.Id).subscribe(x => {
      if (x.IsSuccess) {
        this.model = {
          ...x.Data,
          Id: x.Data?.Id,
          Name: x.Data?.Name,
          ImagePath: x.Data?.ImagePath,
          SortedOrder: Number(x.Data?.SortedOrder),
          LookUpId: x.Data?.LookUpId,
        } as SubLookupMasterPostModel;

        this.isFileAttached = this.model.ImagePath ? false : this.isFileAttached;
      }
    })
  }
  onDocumentAttach(file: FileInfo[]) {
    this.model.ImagePath = file[0].FileBase64;
    this.isFileAttached = true;
  }

  reset() {
    this.model = {} as SubLookupMasterPostModel;
    this.isFileAttached = false;
    this.formgrp.reset();

  }

}
